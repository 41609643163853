import React from "react";
import useAdminSupportStore from "../../../stores/admin/adminSupportStore";
import { convertUTCDateToLocalDateString } from "../../../helpers/dateHelper";

const AdminSupportTicketFileInfo: React.FC = () => {
  const { fileInfo } = useAdminSupportStore();

  return (
    <>
      <div className="mt-4 p-5 border rounded-xl border-tableline grid grid-cols-2 gap-x-4 gap-y-3">
        <p className="text-textsecondary text-lg">Series</p>
        <p className="text-lg text-right overflow-hidden whitespace-normal">{fileInfo?.series}</p>
        <p className="text-textsecondary text-lg">ECU</p>
        <p className="text-lg text-right overflow-hidden whitespace-normal">{fileInfo?.ecu}</p>
        <p className="text-textsecondary text-lg">Engine</p>
        <p className="text-lg text-right overflow-hidden whitespace-normal">{fileInfo?.engine}</p>
        <p className="text-textsecondary text-lg">Software</p>
        <p className="text-lg text-right overflow-hidden whitespace-normal">{fileInfo?.software}</p>
        <p className="text-textsecondary text-lg">Uploaded</p>
        <p className="text-lg text-right">{convertUTCDateToLocalDateString(fileInfo?.uploaded)}</p>  
      </div>
    </>
  );
};

export default AdminSupportTicketFileInfo;
