import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminCapacityTable() {
    return(<>
        <AdminUploadConfigMain type="series" fileName="Series.json" 
              label="Capacity table" path={ConstantRoutes.adminCapacityTable}/>
    </>)
}

export default AdminCapacityTable;