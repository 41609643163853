import { Instagram, Whatsapp, Youtube } from "react-huge-icons/solid";
import { ConstantRoutes } from "../../../core/constantRoutes";
import MainLogo from "../../mainLogo";
import { ConstantInfo } from "../../../core/constantInfo";
import { useTranslation } from "react-i18next";
import { Mastercard, Visa, Paypal, Mir } from "react-payment-logos/dist/flat";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col bg-[#10121B] border-b border-b-[#1A1C29]">
        <div
          className="max-w-screen-2xl w-full place-self-center text-center pt-10
         flex md:flex-row max-md:flex-col justify-between"
        >
          <div>
            <MainLogo mode="dark" onclickUrl={ConstantRoutes.main} />
          </div>
          <div className="flex flex-row justify-between md:w-96 max-md:w-64 max-md:p-4">
            <div className="flex flex-col text-left">
              <p className="text-textsecondary pb-2">NAVIGATION</p>
              <a href={`#${ConstantRoutes.tutorials}`} className="py-2">
                {t("Tutorials")}
              </a>
              <a href={`#${ConstantRoutes.pricing}`} className="py-2">
                {t("Pricing")}
              </a>
              <a href={`#${ConstantRoutes.news}`} className="py-2">
                {t("News")}
              </a>
            </div>

            <div className="flex flex-col text-left">
              <p className="text-textsecondary pb-2"></p>
              <a href={`#${ConstantRoutes.faq}`} className="py-2">
                {t("FAQ")}
              </a>
              <a href={`#${ConstantRoutes.externalDamos}`} className="py-2">
                {t("Damos")}
              </a>
              <a href={`#${ConstantRoutes.externalOriginal}`} className="py-2">
                {t("Original Files")}
              </a>
            </div>
          </div>

          <div className="flex flex-col text-left max-md:p-4">
            <p className="text-textsecondary pb-2">CONTACTS</p>

            <p className="text-textsecondary pt-2">WhatsApp</p>
            <a href={ConstantInfo.phone}>{ConstantInfo.phoneView}</a>

            <p className="text-textsecondary pt-4">Email</p>
            <a href={ConstantInfo.email + "Another question"}>
              {ConstantInfo.emailView}
            </a>

            <div className="flex items-center h-20">
              <a href={ConstantRoutes.youtube}>
                <Youtube className="w-6 h-6 cursor-pointer hover:text-primary mr-2 transition duration-300 ease-in-out" />
              </a>
              <a href={ConstantRoutes.instagram}>
                <Instagram className="w-6 h-6 cursor-pointer hover:text-primary ml-2 transition duration-300 ease-in-out" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-[#10121B] border-b">
        <div
          className="max-w-screen-2xl w-full place-self-center text-center py-10
         flex md:flex-row max-md:flex-col justify-between"
        >
          <p className="text-textsecondary">
            © 2024 Copyright DME Tech. All rights reserved.
          </p>

          <div className="flex flex-row">
            <div className="px-3">
              <Visa />
            </div>
            <div className="px-3">
              <Paypal />
            </div>
            <div className="px-3">
              <Mastercard />
            </div>
            <div className="px-3">
              <Mir />
            </div>
          </div>

          <a href="#privacy">Privacy Policy</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
