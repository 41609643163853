import axios, { AxiosResponse } from "axios";
import getConfig from "../../config/configUtil";

const conf = getConfig;

export interface WorkTimeResult {
  hours: number;
  minutes: number;
}

class WorkTimeService {
  async getWorkTimes(): Promise<WorkTimeResult> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "admin/worktime/get");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch work time settings");
    }
  }

  async saveWorkTimes(workTimes: WorkTimeResult) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/worktime/save", workTimes);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to save work time settings");
    }
  }  
}

export default WorkTimeService;
