import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../stores/authStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useModalAuthorizationStore } from "../../../stores/modalAuthorizationStore";
import { useModalRefillBalanceStore } from "../../../stores/modalRefillBalanceStore";

type InstructionStepProps = {
    text: string;
    icon: React.ElementType;
    isRotated: boolean;
    link: string;
  };

function InstructionStep({ text, icon: Icon, isRotated, link}: InstructionStepProps) {
    const { isAuthenticated } = useAuthStore();
    const navigate = useNavigate();

    const doNavigation = (link: string) => {
        switch (link){
            case ConstantRoutes.register:
                useModalAuthorizationStore.setState({
                    modalOpen: true,
                    activeTab: "register",
                  })
                break;
            case ConstantRoutes.topUpBalance:
                useModalRefillBalanceStore.setState({
                    modalOpen: true
                })
                break;
            default:
                if (isAuthenticated())                    
                    navigate(link)
                else
                    useModalAuthorizationStore.setState({
                        modalOpen: true,
                        activeTab: "login",
                    })
        }
    }
    
    return (
        <div className="rounded-xl p-1px bg-gradient-to-r from-[#FFFFFF3F] to-transparent 
            hover:shadow-md hover:scale-105 transition duration-300 cursor-pointer max-md:w-full max-md:mb-6"
            onClick={() => doNavigation(link)}>
            <div className="rounded-xl bg-mainpagebg">
                <div className="rounded-xl p-4 flex items-center 
                    justify-start md:w-80 max-md:w-full h-28  bg-gradient-to-r from-[#191829] to-transparent">
                    <div className="p-4 bg-black rounded-xl flex items-center justify-center w-20 h-20">
                        <Icon className={`text-primary w-10 h-12 ${isRotated ? ' -rotate-90' : ''}`} />
                    </div>
                    <p className="text-lg flex items-center pl-4">{text}</p>
                </div>
            </div>
        </div>
    );
  }

export default InstructionStep