import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/authStore";
import AuthServiceInstance from "../../api/authServices";
import { useState } from "react";

const RegisterForm: React.FC = () => {
  const {
    email,
    password,
    repeatPassword,
    setEmail,
    setPassword,
    setRepeatPassword,
    message,
    setMessage,
    isSuccess,
    setIsSuccess
  } = useAuthStore();
  const { t } = useTranslation();
  const [registrationInfo, setRegistrationInfo] = useState(String);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setMessage('');
    e.preventDefault();

    const registrationResult = await AuthServiceInstance.register({
      email,
      password,
    });

    setEmail("");
    setPassword("");
    setRepeatPassword("");

    if (registrationResult) {
      setMessage(t('Registration success'));
      setIsSuccess(true);
    }
    else{
      setMessage(t('Registration error'))
      setIsSuccess(false);
    }
  };

  const checkFields = async (email: string, password: string, repeatPassword: string) => {
    if (email.indexOf("@") < 0) 
      setRegistrationInfo('Invalid email address entered');
    else if (password.trim().length < 6)
      setRegistrationInfo('Password must be at least 6 characters long');
    else if (password !== repeatPassword)
      setRegistrationInfo('The passwords entered do not match');
    else
      setRegistrationInfo('')
  }

  const isDisabled =
    email.trim() === "" ||
    password.trim() === "" ||
    repeatPassword.trim() === "" ||
    password !== repeatPassword ||
    password.length < 6 ||
    email.indexOf("@") < 0;

  return (
    <>
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder={t("Email")}
        value={email}
        onChange={(e) => {setEmail(e.target.value); checkFields(e.target.value, password, repeatPassword);}}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3 rounded-md p-2"
      />
      <input
        type="password"
        placeholder={t("Password")}
        value={password}
        onChange={(e) => {setPassword(e.target.value); checkFields(email, e.target.value, repeatPassword);}}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3  rounded-md p-2"
      />
      <input
        type="password"
        placeholder={t("Repeat password")}
        value={repeatPassword}
        onChange={(e) => {setRepeatPassword(e.target.value); checkFields(email, password, e.target.value);}}
        className="block mb-4 w-full  outline-none px-2 py-1 bg-content3  rounded-md p-2"
      />
      {(registrationInfo.length > 1) &&
        <p className="text-danger">{t(registrationInfo)}</p>
      }
      <button
        type="submit"
        className={`bg-primary rounded-md px-4 py-2 ${
          isDisabled ? "opacity-10 cursor-not-allowed" : "opacity-100"
        } transition duration-300 ease-in-out`}
        disabled={isDisabled}
      >
        {t("Register")}
      </button>
    </form>
    {(message.length > 1) &&
        <h1 className={`p-2 text-lg ${isSuccess ? "text-success" : "text-danger"}`}>{message}</h1>
      }
    </>
  );
};

export default RegisterForm;
