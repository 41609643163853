import axios, { AxiosResponse } from 'axios';
import getConfig from '../config/configUtil';

const conf = getConfig;

export interface OrderHistoryRecordResult {
    id: number;
    series: string;
    ecu: string;
    engine: string;
    software: string;
    options: string[];
    uploaded: string;
    status: number;
}

class OrdersService {
    async orders(): Promise<OrderHistoryRecordResult[]> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'orderHistory/orders');
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch orders');
        }
    }

    async orderInfo(id: number) : Promise<OrderHistoryRecordResult> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'orderHistory/order/info/' + id);
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch order info');
        }
    }

    async downloadFile(id: number): Promise<void> {
        try {
            const fileNameResponse: AxiosResponse = await axios.get(conf.BACKEND + 'orderHistory/order/name/' + id, {
                responseType: 'text'
            });

            let filename = fileNameResponse.data;

            const response: AxiosResponse = await axios.get(conf.BACKEND + 'orderHistory/order/' + id, {
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
            throw new Error('Failed to download file');
        }
    }    
}

export default OrdersService;