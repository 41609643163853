type MenuItemProps = {
    text: string;
    icon: React.ElementType;
    onClick: () => void;
    isSelected: boolean;
  };

function MenuItem({ text, icon: Icon , onClick, isSelected }: MenuItemProps) {
    return (
        <div
        className={`flex items-center py-3 lg:px-2 xl:px-4 cursor-pointer rounded-md 
        border border-solid border-transparent hover:border-primary hover:border-opacity-100 my-2
        transition duration-300 ease-in-out
        ${isSelected ? 'bg-primary' : ''}`}
        onClick={onClick}
        >
            <Icon className={`w-6 h-6 mr-2
            ${isSelected ? 'text-foreground' : 'text-textsecondary' }`} />
            <span>{text}</span>
        </div>
    );
  }

export default MenuItem