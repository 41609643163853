import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdminSupportChat from "./adminSupportChat";
import { useParams } from "react-router-dom";
import SupportTicketRightPart from "./adminSupportTicketRightPart";

const AdminSupportCenterChat: React.FC = () => {
  const { t } = useTranslation();

  const { ticket } = useParams<{ ticket: string }>();

  useEffect(() => {}, []);

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="flex-grow flex max-md:flex-col">
          <div className="w-full md:hidden mb-6">
            <SupportTicketRightPart />
          </div>

          <AdminSupportChat ticket={Number(ticket)} />

          <div className="w-1/3 h-full pl-6 max-md:hidden">
            <SupportTicketRightPart />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSupportCenterChat;
