import React from 'react';
import { useTranslation } from 'react-i18next';

interface NewsItemProps {
  title: string;
  text: string;
  date: Date;
}

const NewsItem: React.FC<NewsItemProps> = ({ title, text, date }) => {
  const { t, i18n } = useTranslation();

  const formattedDate = new Intl.DateTimeFormat(i18n.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);

  return (
    <div className="border-b-textsecondary border-b-[1px] p-4 flex flex-row justify-between">
      <div>
        <p className="text-xl p-2">{title}</p>
        <p className="text-textsecondary p-2">{text}</p>
      </div>
      <div>
        <p className="rounded-full bg-content2 p-3 text-nowrap">{formattedDate}</p>
      </div>
    </div>
  );
};

export default NewsItem;