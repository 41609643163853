import React from "react";
import AdminTableServiceMain from "../adminTableServiceMain/adminTableServiceMain";

const AdminOfflineService: React.FC = () => {
  return(
      <>
          <AdminTableServiceMain label="Admin offline service" path="offlineservice" />
      </>
  )
}

export default AdminOfflineService;
