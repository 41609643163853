import React, { useEffect, useRef, useState } from "react";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";

type DropdownItem = {
  id: string;
  label: string;
};

type DropdownProps = {
  items: DropdownItem[];
  onSelect: (item: DropdownItem) => void;
  caption: string;
  reverseDirection?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  items,
  onSelect,
  caption,
  reverseDirection = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (item: DropdownItem) => {
    onSelect(item);
    toggleDropdown();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <div>
        <div className="flex cursor-pointer" onClick={toggleDropdown}>
          <p className="pl-4">{caption}</p>
          <ChevronDownIcon
            className={`w-6 transition-all duration-300 ${
              isOpen ? "rotate-180" : "rotate-0"
            } ${isOpen ? "text-primary" : "text-textsecondary"}`}
          />
        </div>
      </div>

      <div
        className={`absolute overflow-visible right-0 ${
          reverseDirection ? "bottom-full mb-2" : "top-full mt-2"
        } w-auto rounded-md shadow-lg bg-content1 focus:outline-none z-50 transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
      >
        {isOpen && (
          <div
            className="p-2"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {items.map((item) => (
              <p
                key={item.id}
                onClick={() => handleItemClick(item)}
                className="block px-4 py-2 bg-content1 hover:bg-primary transition duration-300 ease-in-out cursor-pointer rounded-md"
                role="menuitem"
              >
                {item.label}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;