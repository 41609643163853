import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminFaq() {
    return(<>
        <AdminUploadConfigMain type="faq" fileName="Faq.json" 
              label="FAQ" path={ConstantRoutes.adminFaq}/>
    </>)
}

export default AdminFaq;