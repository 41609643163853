import axios, { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfig from '../../config/configUtil';

const conf = getConfig;

export interface OfflineServiceRecordResult {
    id: number;
    email: string;
    series: string;
    ecu: string;
    engine: string;
    software: string;
    options: string[];
    uploaded: string;
    status: number;
    comment: string;
}

interface UploadOptions {
    onProgress?: (progress: number) => void;
  }

class OfflineService {
    async orders(mode: string): Promise<OfflineServiceRecordResult[]> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + `admin/${mode}/orders`);
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch orders');
        }
    }

    async orderInfo(id: number) : Promise<OfflineServiceRecordResult> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'admin/offlineservice/order/info/' + id);
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch order info');
        }
    }

    async cancelOrder(id: number) : Promise<void> {
      try {
        await axios.post(conf.BACKEND + 'admin/offlineservice/order/cancel/' + id);
      } catch (error) {
        console.log(error);
        throw new Error('Failed to cancel order');
      }
    }

    async downloadFile(id: number): Promise<void> {
        try {
            const fileNameResponse: AxiosResponse = await axios.get(conf.BACKEND + 'admin/offlineservice/order/name/' + id, {
                responseType: 'text'
            });

            let filename = fileNameResponse.data;

            const response: AxiosResponse = await axios.get(conf.BACKEND + 'admin/offlineservice/order/' + id, {
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
            throw new Error('Failed to download file');
        }
    }

    async uploadFile(
        file: File,
        orderId: number,
        options?: UploadOptions
      ): Promise<string> {
        const formData = new FormData();
        formData.append("file", file, file.name);
    
        const config: AxiosRequestConfig = {
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (options?.onProgress) {
              const progress = Math.round(
                (progressEvent.loaded / (progressEvent.total ?? 1)) * 90
              );
              options.onProgress(progress);
            }
          },
        };
    
        try {
          const response: AxiosResponse<string> = await axios.post(
            conf.BACKEND + "admin/offlineservice/order/upload/" + orderId,
            formData,
            config
          );
          console.log("File uploaded successfully:", response.data);
          return response.data;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error("Axios Error:", error.message);
          } else {
            console.error("Error uploading file:", error);
          }
          throw error;
        }
      }
}

export default OfflineService;