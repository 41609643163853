import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AddCircle, MinusCircle } from "react-huge-icons/solid";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useUsersStore } from "../../../stores/admin/usersStore";
import { TableCell, TableHeader } from "../../table/tableCellHeader";
import { useModalConfirmActionStore } from "../../../stores/admin/modalConfirmActionStore";
import UsersService from "../../../api/admin/usersService";
import Checkbox from "../../checkbox/checkbox";

const AdminUsers: React.FC = () => {
  const { t } = useTranslation();
  const { users, fetchUsers } = useUsersStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const { setMessage, setModalOpen, setConfirmAction } =
    useModalConfirmActionStore();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({ label: "Users", path: ConstantRoutes.adminUsers });

    fetchUsers();
  }, []);

  function addTokens(id: any, email: any): void {
    setMessage(
      t("Are you sure you want to add tokens to user") + " " + email + "?"
    );
    setConfirmAction(async () => {
      const usersService = new UsersService();
      await usersService.addTokens(id);
      await fetchUsers();
    });
    setModalOpen(true);
  }

  function removeTokens(id: any, email: any): void {
    setMessage(
      t("Are you sure you want to remove tokens from user") + " " + email + "?"
    );
    setConfirmAction(async () => {
      const usersService = new UsersService();
      await usersService.removeTokens(id);
      await fetchUsers();
    });
    setModalOpen(true);
  }

  function deleteUser(id: any, email: any): void {
    setMessage(t("Are you sure you want to DELETE user") + " " + email + "?");
    setConfirmAction(async () => {
      const usersService = new UsersService();
      await usersService.deleteUser(id);
      await fetchUsers();
    });
    setModalOpen(true);
  }

  function resetPassword(id: any, email: any): void {
    const newPassword = Array.from(
      { length: 8 },
      () =>
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*"[
          Math.floor(Math.random() * 70)
        ]
    ).join("");
    setMessage(
      t("Are you sure you want to reset password for user") +
        " " +
        email +
        "? New password is: " +
        newPassword
    );
    setConfirmAction(async () => {
      const usersService = new UsersService();
      await usersService.resetPassword(id, newPassword);
      await fetchUsers();
    });
    setModalOpen(true);
  }

  const handleCheckboxIsEmailConfirmedChange = async (
    isChecked: boolean,
    userId: string
  ) => {
    const usersService = new UsersService();
    await usersService.confirmEmail(userId);
    await fetchUsers();
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="rounded-xl overflow-hidden border border-tableline max-md:hidden">
          <table className="w-full">
            <thead className="bg-tablebg2">
              <tr>
                <TableHeader additionalClassNames="w-52">Id</TableHeader>
                <TableHeader additionalClassNames="w-14">
                  Email/Confirmed
                </TableHeader>
                <TableHeader additionalClassNames="w-14">Tokens</TableHeader>
                <TableHeader additionalClassNames="w-25">Add</TableHeader>
                <TableHeader additionalClassNames="w-25">Remove</TableHeader>
                <TableHeader additionalClassNames="w-25">Delete</TableHeader>
                <TableHeader additionalClassNames="w-25">Reset</TableHeader>
              </tr>
            </thead>
            <tbody>
              {users.map((user: any, index: number) => (
                <tr
                  className={index % 2 === 0 ? "bg-tablebg" : "bg-tablebg2"}
                  key={index}
                >
                  <TableCell>
                    <p className="text-sm">{user.id}</p>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      key={user.email}
                      id={user.email}
                      checkboxText={user.email}
                      tooltipText={"Confirm Email"}
                      checked={user.isEmailConfirmed}
                      onChange={(isChecked, checkboxText) =>
                        handleCheckboxIsEmailConfirmedChange(isChecked, user.id)
                      }
                    />
                  </TableCell>
                  <TableCell>{user.coins}</TableCell>
                  <TableCell>
                    <div className="flex-1">
                      <button
                        className="flex items-center justify-center border-success 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-success h-10 w-25 transition duration-300 ease-in-out"
                        onClick={() => addTokens(user.id, user.email)}
                      >
                        <AddCircle className="w-6 h-6" />
                        <p className="px-2">
                          {t("Add")}
                        </p>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex-1">
                      <button
                        className="flex items-center justify-center border-secondary 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-secondary h-10 w-30 transition duration-300 ease-in-out"
                        onClick={() => removeTokens(user.id, user.email)}
                      >
                        <MinusCircle className="w-6 h-6" />
                        <p className="px-2">
                         {t("Remove")}
                        </p>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex-1">
                      <button
                        className="flex items-center justify-center border-danger 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-danger h-10 w-25 transition duration-300 ease-in-out"
                        onClick={() => deleteUser(user.id, user.email)}
                      >
                        {t("Delete")}
                      </button>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex-1">
                      <button
                        className="flex items-center justify-center border-primary 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-primary h-10 w-25 transition duration-300 ease-in-out"
                        onClick={() => resetPassword(user.id, user.email)}
                      >
                        {t("Reset")}
                      </button>
                    </div>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          {users.map((user: any, index: number) => (
            <div
              className="rounded-xl overflow-hidden border border-tableline bg-tablebg my-4 p-4
                  flex flex-wrap"
            >
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Id</p>
                <p>{user.id}</p>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Email</p>
                <Checkbox
                  key={user.email}
                  id={user.email}
                  checkboxText={user.email}
                  tooltipText={"Confirm Email"}
                  checked={user.isEmailConfirmed}
                  onChange={(isChecked, checkboxText) =>
                    handleCheckboxIsEmailConfirmedChange(isChecked, user.id)
                  }
                />
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Tokens</p>
                <p>{user.coins}</p>
              </div>
              <div className="p-2 w-full">
                <p className="text-textsecondary">Add</p>
                <button
                  className="flex items-center justify-center border-success 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-success h-10 w-25 transition duration-300 ease-in-out"
                  onClick={() => addTokens(user.id, user.email)}
                >
                  {t("Add")}
                </button>
              </div>
              <div className="p-2 w-full">
                <p className="text-textsecondary">Remove</p>
                <button
                  className="flex items-center justify-center border-secondary 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-secondary h-10 w-25 transition duration-300 ease-in-out"
                  onClick={() => removeTokens(user.id, user.email)}
                >
                  {t("Remove")}
                </button>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Delete</p>
                <button
                  className="flex items-center justify-center border-danger 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-danger h-10 w-25 transition duration-300 ease-in-out"
                  onClick={() => deleteUser(user.id, user.email)}
                >
                  {t("Delete")}
                </button>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Reset</p>
                <button
                  className="flex items-center justify-center border-primary 
                          border border-solid rounded-lg px-3 cursor-pointer hover:bg-primary h-10 w-25 transition duration-300 ease-in-out"
                  onClick={() => resetPassword(user.id, user.email)}
                >
                  {t("Reset")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
