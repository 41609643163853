import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminNews() {
    return(<>
        <AdminUploadConfigMain type="news" fileName="News.json" 
              label="News" path={ConstantRoutes.adminNews}/>
    </>)
}

export default AdminNews;