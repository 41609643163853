import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import SupportItem from './supportRecord';
import useSupportStore from '../../stores/chatStore';
import SupportService, { ChatResult } from '../../api/supportService';
import { convertUTCDateToLocalDateString } from '../../helpers/dateHelper';
import useBreadcrumbsStore from '../../stores/breadcrumbStore';
import { ConstantRoutes } from '../../core/constantRoutes';

const Support: React.FC = () => {
  const { t } = useTranslation();
  const { chats, fetchChats } = useSupportStore();
  const {addBreadcrumb, clearBreadcrumbs} = useBreadcrumbsStore();  

  const supportService = new SupportService(); 

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({label: 'Support', path: ConstantRoutes.support})

    fetchChats();    
  }, []); 

  return (
    <div className="relative flex-1 flex flex-col">

      {chats.map((chat: ChatResult, index: number) => (
        <SupportItem title={chat.title} date={convertUTCDateToLocalDateString(chat.createdOn.toString())} 
          showDot={chat.userNewMessagesCount > 0} ticket={chat.userFileId}/>
      ))}
    </div>
  );
};

export default Support;