import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBreadcrumbsStore from '../../stores/breadcrumbStore';
import { ConstantRoutes } from '../../core/constantRoutes';

const DamosMappack: React.FC = () => {
  const { t } = useTranslation();
  const {addBreadcrumb, clearBreadcrumbs} = useBreadcrumbsStore();  

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({label: 'Damos', path: ConstantRoutes.damosMappack})
  }, []);

  return (
    <div className='flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72'>
            <div className='text-center'>
                <h1 className='text-2xl pb-4'>{t('Page still in development')}</h1>

                <a className="text-center cursor-pointer rounded-lg bg-primary h-11 w-full hover:animate-pulse transition duration-300 ease-in-out mt-4
                p-3"
                  href={ConstantRoutes.externalDamos}
                >
                  {t("Open old version of the site")}
                </a>
            </div>
    </div>
  );
};

export default DamosMappack;