import { create } from "zustand";
import WorkTimeService, { WorkTimeResult } from "../../api/admin/workTimeService";

interface WorkTimeStore {
  workTime: WorkTimeResult | undefined;
  fetchWorkTime: () => Promise<void>;
  updateHours: (newHours: number) => void;
  updateMinutes: (newMinutes: number) => void;
}

export const useWorkTimeStore = create<WorkTimeStore>((set) => ({
  workTime: undefined,
  fetchWorkTime: async () => {
    const workTimeService = new WorkTimeService();
    try {
      const workTime = await workTimeService.getWorkTimes();
      set({ workTime });
    } catch (error) {}
  },
  updateHours: (newHours: number) => {
    set((state) => ({
      workTime: state.workTime ? { ...state.workTime, hours: newHours } : undefined,
    }));
  },
  updateMinutes: (newMinutes: number) => {
    set((state) => ({
      workTime: state.workTime ? { ...state.workTime, minutes: newMinutes } : undefined,
    }));
  }
}));
