import { useTranslation } from "react-i18next";

function OrderStatus({ status }: { status: number }) {
  const { t } = useTranslation();

  return (
    <>
      {(() => {
        switch (status) {
          case 3:
          case 5:
            return <p className="text-success">{t("Processed")}</p>;
          case 6:
          case 7:
            return <p className="text-orange-600">{t("Canceled")}</p>;
          default:
            return <p className="text-careful">{t("In processing")}</p>;
        }
      })()}
    </>
  );
}

export default OrderStatus;
