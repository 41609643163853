import { create } from "zustand";
import UsersService, { UsersRootResult } from "../../api/admin/usersService";

interface UsersStore {
  users: UsersRootResult[];
  fetchUsers: () => Promise<void>;
}

export const useUsersStore = create<UsersStore>((set) => ({
  users: [],
  fetchUsers: async () => {
    const usersService = new UsersService();
    try {
      const users = await usersService.getUsers();
      set({ users });
    } catch (error) {}
  }
}));
