import AdminTableServiceMain from "../adminTableServiceMain/adminTableServiceMain";

const AdminOnlineService: React.FC = () => {
    return(
        <>
            <AdminTableServiceMain label="Admin online service" path="onlineservice" />
        </>
    )
}

export default AdminOnlineService;