import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OrdersService from "../../../api/orderHistoryService";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { TableCell, TableHeader } from "../../table/tableCellHeader";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import OrderStatus from "../../common/orderStatus/orderStatus";

interface AdminTableServiceMainProps {
  label: string,
  path: string,
}

const AdminTableServiceMain: React.FC<AdminTableServiceMainProps> = ({label, path}) => {
  const { t } = useTranslation();
  const { orders, fetchOrders, clearOrders } = useOfflineServiceStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const navigate = useNavigate();
  const ordersService = new OrdersService();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({
      label: t(label),
      path: ConstantRoutes.adminOfflineService,
    });

    clearOrders();
    fetchOrders(path);
  }, []);

  function workWithFile(id: any): void {
    navigate(`/admin-tech/${path}/work/${id}`);
  }

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="rounded-xl overflow-hidden border border-tableline max-md:hidden">
          <table className="w-full">
            <thead className="bg-tablebg2">
              <tr>
                <TableHeader additionalClassNames="w-20">№</TableHeader>
                <TableHeader additionalClassNames="w-20">Email</TableHeader>
                <TableHeader additionalClassNames="w-20">Series</TableHeader>
                <TableHeader additionalClassNames="w-52">Ecu</TableHeader>
                <TableHeader additionalClassNames="w-20">Engine</TableHeader>
                <TableHeader additionalClassNames="w-52">Software</TableHeader>
                <TableHeader additionalClassNames="w-52">Options</TableHeader>
                <TableHeader additionalClassNames="w-32">Uploaded</TableHeader>
                <TableHeader additionalClassNames="w-32">Status</TableHeader>
                <TableHeader additionalClassNames="w-40">Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any, index: number) => (
                <tr
                  className={index % 2 === 0 ? "bg-tablebg" : "bg-tablebg2"}
                  key={index}
                >
                  <TableCell>
                    <div className="flex flex-nowrap">
                      {order.isNewMessages && (
                        <div className="w-2 h-2 bg-primary rounded-full mt-2 -ml-2"></div>
                      )}
                      <div>№{order.id}</div>
                    </div>
                  </TableCell>
                  <TableCell>{order.email}</TableCell>
                  <TableCell>{order.series}</TableCell>
                  <TableCell>{order.ecu}</TableCell>
                  <TableCell>{order.engine}</TableCell>
                  <TableCell>{order.software}</TableCell>
                  <TableCell>
                    <div className="flex flex-wrap">
                      {order.options !== undefined &&
                        order.options.map((option: any, subindex: number) => (
                          <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                            {option}
                          </p>
                        ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    {new Date(order.uploaded).toLocaleDateString("ru-RU")}
                  </TableCell>
                  <TableCell>
                    <OrderStatus status={order.status} />
                  </TableCell>
                  <TableCell>
                    <div className="flex-1">
                      <button
                        className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary min-h-min w-38 py-2"
                        onClick={() => workWithFile(order.id)}
                      >
                        {t("Work with file")}
                      </button>
                    </div>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          {orders.map((order: any, index: number) => (
            <div
              className="rounded-xl overflow-hidden border border-tableline bg-tablebg my-4 p-4
                  flex flex-wrap"
            >
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">№</p>
                <p>№ {order.id}</p>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Email</p>
                <p>{order.email}</p>
              </div>

              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Series</p>
                <p>{order.series}</p>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">ECU</p>
                <p>{order.ecu}</p>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Engine</p>
                <p>{order.engine}</p>
              </div>
              <div className="p-2 w-full">
                <p className="text-textsecondary">Software</p>
                <p>{order.software}</p>
              </div>
              <div className="p-2 w-full">
                <p className="text-textsecondary">Options</p>
                <div className="flex flex-wrap">
                  {order.options !== undefined &&
                    order.options.map((option: any, subindex: number) => (
                      <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                        {option}
                      </p>
                    ))}
                </div>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Uploaded</p>
                <p> {new Date(order.uploaded).toLocaleDateString("ru-RU")}</p>
              </div>
              <div className="p-2 w-1/2">
                <p className="text-textsecondary">Status</p>
                <p>
                  {" "}
                  <OrderStatus status={order.status} />
                </p>
              </div>
              <div className="flex w-full justify-center border-t border-tableline pt-4">
                <button
                  className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-11/12 my-2 mx-2"
                  onClick={() => workWithFile(order.id)}
                >
                  {t("Work with file")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminTableServiceMain;
