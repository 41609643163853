import axios, { AxiosResponse } from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface WorktimeResult {
  open: string;
  close: string;
}

class WorktimeService {
  async getWorktimes(): Promise<WorktimeResult> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "worktime/get");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch Worktimes");
    }
  }
}

export default WorktimeService;
