import React, { useEffect } from "react";
import SupportTicketFileInfo from "./supportTicketFileInfo";
import { useParams } from "react-router-dom";
import useSupportStore from "../../stores/chatStore";
import { useTranslation } from "react-i18next";
import OrdersService from "../../api/orderHistoryService";

const SupportTicketRightPart: React.FC = () => {
  const { t } = useTranslation();

  const { ticket } = useParams<{ ticket: string }>();
  const { fileInfo, fetchFileInfo } = useSupportStore();
  const ordersService = new OrdersService();

  useEffect(() => {
    fetchFileInfo(Number(ticket));
  }, []);

  function downloadFile(id: any): void {
    ordersService.downloadFile(id);
  }  

  return (
    <>
      <p className="text-xl">{t('File info')}</p>

      <SupportTicketFileInfo />

      <p className="mt-5 text-xl">Option</p>

      <div className="mt-4 p-5 border rounded-xl border-tableline flex">
        <div className="flex flex-wrap">
        {fileInfo?.options !== undefined &&
          fileInfo.options.map((option: any, subindex: number) => (
            <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
              {option}
            </p>
          ))}
          </div>
      </div>

      <div className="mt-6">
        {fileInfo !== null &&        <button
          className="border border-primary rounded-md md:w-1/2 max-md:w-full h-11 items-center 
                justify-center hover:bg-primary transition duration-300 ease-in-out"
          onClick={() => downloadFile(fileInfo?.id)}
        >
          {t('Download')}
        </button>}
      </div>
    </>
  );
};

export default SupportTicketRightPart;
