import { SettingShort } from "react-huge-icons/outline";
import { useModalBusyLoadingStore } from "../../stores/modalBusyLoadingStore";

const BusyLoading: React.FC = () => {
    const isOpen = useModalBusyLoadingStore(state => state.modalOpen);

    if (!isOpen) return null;

    return(<>
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-content3 bg-opacity-90 z-50">
      <div className="w-24 h-24 relative">
        <div className="absolute top-0 left-0">
          <SettingShort className="w-20 h-20 text-textsecondary animate-spin rotate-180" />
        </div>
        <div className="absolute bottom-0 right-0">
          <SettingShort className="w-12 h-12 text-textsecondary animate-spin" />
        </div>
      </div>
    </div>
    </>)
}

export default BusyLoading;