import { useTranslation } from "react-i18next";
import { ConstantRoutes } from "../../../core/constantRoutes";
import PricingCard from "./pricingCard";

const Pricing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col" id={ConstantRoutes.pricing}>
        <div className="max-w-screen-2xl w-full place-self-center text-center pb-10 md:pt-40 max-md:pt-10">
          <h1 className="text-4xl font-bold">{t('Pricing2')}</h1>
          <p className="py-8 text-textsecondary max-md:p-4">{t('Fast online file processing')}</p>

          <div className="flex max-md:flex-wrap md:flex-row justify-evenly">
            <PricingCard offPercent={0} tokens={10} url={"https://dme-tech.store/product/tokens-10/"} />
            <PricingCard offPercent={0} tokens={50} url={"https://dme-tech.store/product/tokens-50/"}/>
            <PricingCard offPercent={5} tokens={100} url={"https://dme-tech.store/product/100-tokens/"}/>
            <PricingCard offPercent={10} tokens={200} url={"https://dme-tech.store/product/200-tokens/"}/>
            <PricingCard offPercent={15} tokens={300} url={"https://dme-tech.store/product/300-tokens/"}/>
            <PricingCard offPercent={20} tokens={500} url={"https://dme-tech.store/product/500-tokens/"}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
