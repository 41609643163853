import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminSignatures() {
    return(<>
        <AdminUploadConfigMain type="signatures" fileName="Signatures.csv" 
              label="Signatures" path={ConstantRoutes.adminSignatures}/>
    </>)
}

export default AdminSignatures;