import { create } from "zustand";

interface AppState {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}

export const useModalBusyLoadingStore = create<AppState>((set) => ({
  modalOpen: false,
  setModalOpen: (isOpen) => set({ modalOpen: isOpen }),
}));