import React from "react";
import { ConstantInfo } from "../../core/constantInfo";
import { Mail, Whatsapp } from "react-huge-icons/outline";
import Checkbox from "../checkbox/checkbox";
import { useTranslation } from "react-i18next";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { Patch } from "../../models/firmware";
import { useOfflineSettingsStore } from "../../stores/offlineSettingsStore";
import { useAuthStore } from "../../stores/authStore";

interface TgFlashSlaveProps {
  patches: Patch[];
}

const TgFlashSlaveInfo: React.FC<TgFlashSlaveProps> = ({ patches }) => {
  const { t } = useTranslation();
  const {
    comment,
    setComment,
    checkedTgFlashPatches,
    setCheckedTgFlashPatches,
    setMaxPatchPrice,
    setPlusPatchPrice,
    firmwareInfo,
  } = useUploadStore();
  const { offlineSettings } = useOfflineSettingsStore();

  const handleCheckboxPatchChange = (
    isChecked: boolean,
    checkboxText: string
  ) => {
    let updatedPatches: Patch[];
    const patch = firmwareInfo?.patches.find(
      (x) => x.name === checkboxText && x.patchMode === 2
    )!;
    if (isChecked) {
      updatedPatches = [...checkedTgFlashPatches, patch];
    } else {
      updatedPatches = checkedTgFlashPatches.filter(
        (p) => p.name !== patch.name
      );
    }

    setCheckedTgFlashPatches(updatedPatches);

    const maxPrice =
      updatedPatches.length > 0
        ? Math.max(...updatedPatches.map((patch) => patch.price))
        : null;

    setMaxPatchPrice(maxPrice);

    const plusPrice =
      updatedPatches.length > 0
        ? updatedPatches.reduce((sum, current) => sum + current.pricePlus, 0)
        : null;

    setPlusPatchPrice(plusPrice);
  };

  const { lang } = useAuthStore();

  const offlineSettingsForCurrentLanguage = offlineSettings.find(
    (f) => f.lang === lang
  );

  return (
    <>
      <div className="rounded-xl bg-content2 mb-8 ">
        <div className="">
          <div className="flex flex-row">
            <div className="p-8">
              <h1 className="text-2xl font-medium pb-5">
                {t("TGFlash Slave")}
              </h1>
              <p className="text-textsecondary">
                {t(
                  "Your file is encrypted; the server cannot process such files automatically"
                )}
              </p>
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex flex-row items-center pl-8 pr-8">
              <Whatsapp className="w-8 h-8 my-3 pr-2" />
              <p className="pr-1">(Whatsapp)</p>
              <a href={ConstantInfo.phone}>{ConstantInfo.phoneView}</a>
            </div>
            <div className="flex flex-row items-center">
              <Mail className="w-8 h-8 my-3 pr-2" />
              <a className="pr-2" href={ConstantInfo.email + "Offline service"}>
                {ConstantInfo.emailView}
              </a>
            </div>
          </div>
        </div>

        <div className="p-8 flex flex-wrap">
          {patches.map((patch, index) => (
            <Checkbox
              key={index + "offline"}
              id={index + "offline"}
              checkboxText={patch.name}
              tooltipText={
                offlineSettingsForCurrentLanguage?.records.find(
                  (x) => x.header === patch.name
                )?.description ?? ""
              }
              checked={checkedTgFlashPatches.some((p) => p.name === patch.name)}
              onChange={(isChecked, checkboxText) =>
                handleCheckboxPatchChange(isChecked, checkboxText)
              }
            />
          ))}
        </div>

        <div className="p-8">
          <p className="pb-5">{t("Your comments about the order")}</p>

          <textarea
            className="h-40 rounded-2xl bg-content3 w-full mb-5 px-5 py-3 resize-none"
            placeholder={t("Write message")}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default TgFlashSlaveInfo;
