import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import HomeHeader from "./header/homeHeader";
import DmeTechInfo from "./dmeTechInfo/dmeTechInfo";
import RemappingDataBase from "./remappingDataBase/remappingDataBase";
import Tutorials from "./tutorials/tutorials";
import Pricing from "./pricing/pricing";
import News from "./news/news";
import Faq from "./faq/faq";
import Footer from "./footer/footer";
import RadialGradient from "./additionalDesign/radialGradient";
import AuthServiceInstance from "../../api/authServices";
import { useAuthStore } from "../../stores/authStore";
import { ConstantRoutes } from "../../core/constantRoutes";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import MainMdMenu from "./mainMdMenu/mainMdMenu";
import useLeftMenuStore from "../../stores/leftMenuStore";

const MainPage: React.FC = () => {
  const [searchparams] = useSearchParams();
  const token = searchparams.get("token");
  const resetPasswordToken = searchparams.get("resetPassword");
  const email = searchparams.get("email");
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();
  const { isOpen } = useLeftMenuStore();

  useEffect(() => {
    if (isAuthenticated() && token && token?.length > 4) {
      AuthServiceInstance.confirmEmail(token);
      navigate(ConstantRoutes.fileProcessing, { replace: true });
      /*AuthServiceInstance.logout();
      navigate(ConstantRoutes.main);    */
    }

    if (
      !isAuthenticated() &&
      resetPasswordToken &&
      resetPasswordToken?.length > 4 &&
      email &&
      email?.length > 4
    ) {
      AuthServiceInstance.resetPassword(email, resetPasswordToken);
      setModalSuccessOpen(true);
      navigate(ConstantRoutes.main);
    }
  }, []);

  const setModalSuccessOpen = useModalRefillBalanceStore(
    (state) => state.setModalSuccessOpen
  );

  return (
    <>
      <div className="bg-mainpagebg">
        <HomeHeader />
        <MainMdMenu />
        <div className={`${isOpen ? "max-md:hidden" : ""} `}>
          <DmeTechInfo />
          <RemappingDataBase />
          <RadialGradient />

          <Tutorials />
          <Pricing />
          <RadialGradient />
          <News />
          <Faq />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainPage;
