import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useEmailStore } from "../../../stores/admin/emailStore";
import EmailSettingsService from "../../../api/admin/emailSettingsService";

const AdminEmail: React.FC = () => {
  const { t } = useTranslation();
  const {
    settings,
    fetchSettings,
    updateFrom,
    updatePassword,
    updateFromName,
    updateSmtpPort,
    updateSmtpAddress,
    updateConfirmSubject,
    updateConfirmMessage,
    updateIsSecure,
    updateForgotPasswordMessage,
    updateForgotPasswordSubject,
    updateResetPasswordMessage,
    updateResetPasswordSubject,
    testEmail,
    updateTestEmail,
    testResponse,
    updateTestResponse,
  } = useEmailStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({ label: "Email", path: ConstantRoutes.adminEmailSettings });

    fetchSettings();
  }, []);

  const saveSettings = () => {
    const emailService = new EmailSettingsService();
    if (settings !== undefined) {
      emailService.saveEmailSettings(settings);
      fetchSettings();
    }
  };

  const sendTestEmail = async () => {
    const emailService = new EmailSettingsService();
    if (settings !== undefined) {
      const response = await emailService.sendTestToken(testEmail);
      updateTestResponse(response);
    }
  };

  return (
    <>
      <div className="flex-1 flex flex-col p-2">
        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("From")}</label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("From")}
            value={settings?.from || ""}
            onChange={(event) => updateFrom(event.target.value)}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("Password")}</label>
          <input
            type="password"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Password")}
            value={settings?.password || ""}
            onChange={(event) => updatePassword(event.target.value)}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("From Name")}</label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("From Name")}
            value={settings?.fromName || ""}
            onChange={(event) => updateFromName(event.target.value)}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("SMTP Port")}</label>
          <input
            type="number"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("SMTP Port")}
            value={settings?.smtpPort || ""}
            onChange={(event) => updateSmtpPort(Number(event.target.value))}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("SMTP Address")}</label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("SMTP Address")}
            value={settings?.smtpAddress || ""}
            onChange={(event) => updateSmtpAddress(event.target.value)}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("Is Secure")}</label>
          <input
            type="checkbox"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            checked={settings?.isSecure || false}
            onChange={(event) => updateIsSecure(event.target.checked)}
          />
        </div>

        <hr />
        <p className="text-2xl">
          {t("Письмо, отправляемое после регистрации с кодом подтверждения")}
        </p>
        <div className="w-full mb-4">
          <label className="text-textsecondary">{t("Confirm Subject")}</label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Confirm Subject")}
            value={settings?.confirmSubject || ""}
            onChange={(event) => updateConfirmSubject(event.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <label className="text-textsecondary">{t("Confirm Message")}</label>
          <textarea
            className="h-40 bg-content2 w-full mb-5 px-5 py-3 resize-none border border-secondary rounded-xl"
            placeholder={t("Confirm Message")}
            value={settings?.confirmMessage || ""}
            onChange={(event) => updateConfirmMessage(event.target.value)}
          />
        </div>

        <hr />
        <p className="text-2xl">
          {t(
            'Письмо, отправляемое после того как клиент нажмёт "забыл пароль" '
          )}
        </p>
        <div className="w-full mb-4">
          <label className="text-textsecondary">
            {t("Forgot Password Subject")}
          </label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Forgot Password Subject")}
            value={settings?.forgotPasswordSubject || ""}
            onChange={(event) =>
              updateForgotPasswordSubject(event.target.value)
            }
          />
        </div>

        <div className="w-full mb-4">
          <label className="text-textsecondary">
            {t("Forgot Password Message")}
          </label>
          <textarea
            className="h-40 bg-content2 w-full mb-5 px-5 py-3 resize-none border border-secondary rounded-xl"
            placeholder={t("Forgot Password Message")}
            value={settings?.forgotPasswordMessage || ""}
            onChange={(event) =>
              updateForgotPasswordMessage(event.target.value)
            }
          />
        </div>

        <hr />
        <p className="text-2xl">
          {t(
            "Письмо, с новым паролем, отправляемое после сброса в админке или после того как клиент подтвердил что забыл пароль "
          )}
        </p>
        <div className="w-full mb-4">
          <label className="text-textsecondary">
            {t("Reset Password Subject")}
          </label>
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Reset Password Subject")}
            value={settings?.resetPasswordSubject || ""}
            onChange={(event) => updateResetPasswordSubject(event.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <label className="text-textsecondary">
            {t("Reset Password Message")}
          </label>
          <textarea
            className="h-40 bg-content2 w-full mb-5 px-5 py-3 resize-none border border-secondary rounded-xl"
            placeholder={t("Reset Password Message")}
            value={settings?.resetPasswordMessage || ""}
            onChange={(event) => updateResetPasswordMessage(event.target.value)}
          />
        </div>

        <button
          className="text-center cursor-pointer rounded-lg bg-primary h-11 w-1/2 hover:animate-pulse transition duration-300 ease-in-out my-4"
          onClick={saveSettings}
        >
          {t("Save")}
        </button>



        <div className="w-full mb-4">
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={"test@address.ru"}
            value={testEmail || ""}
            onChange={(event) => updateTestEmail(event.target.value)}
          />

          <button
            className="text-center cursor-pointer rounded-lg bg-secondary h-11 w-1/2 hover:animate-pulse transition duration-300 ease-in-out my-4"
            onClick={sendTestEmail}
          >
            {t("Send test email")}
          </button>

          <p className="text-2xl">{testResponse}</p>
        </div>
      </div>
    </>
  );
};

export default AdminEmail;
