import { create } from "zustand";

export type FirmwareProcessor = () => Promise<void>;

interface AppState {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalSuccessOpen: boolean;
  setModalSuccessOpen: (isOpen: boolean) => void;
  modalForgotPasswordOpen: boolean;
  setModalForgotPasswordOpen: (isOpen: boolean) => void;
  modalAttentionOpen: boolean;
  modalFirmwareProcessor: FirmwareProcessor,
  setModalAttentionOpen: (isOpen: boolean, modalFirmwareProcessor: FirmwareProcessor) => void;
}

export const useModalRefillBalanceStore = create<AppState>((set) => ({
  modalOpen: false,
  setModalOpen: (isOpen) => set({ modalOpen: isOpen }),
  modalSuccessOpen: false,
  setModalSuccessOpen: (isOpen) => set({ modalSuccessOpen: isOpen }),
  modalForgotPasswordOpen: false,
  setModalForgotPasswordOpen: (isOpen) => set({ modalForgotPasswordOpen: isOpen }),
  modalAttentionOpen: false,
  modalFirmwareProcessor: async () => {return},
  setModalAttentionOpen: (isOpen, firmwareProcessor) => set({modalAttentionOpen: isOpen, modalFirmwareProcessor: firmwareProcessor}),
}));