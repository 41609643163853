import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminCompares() {
    return(<>
        <AdminUploadConfigMain type="compares" fileName="Compares.json" 
              label="Compares" path={ConstantRoutes.adminCompares}/>
    </>)
}

export default AdminCompares;