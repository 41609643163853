import axios, { AxiosError } from "axios";
import getConfig from "../config/configUtil";
import { useAuthStore } from "../stores/authStore";

const conf = getConfig;

interface UserData {
  email: string;
  balance: number;
  token: string;
  isEmailConfirmed: boolean;
  roles: string[]
  // Add other user information here if needed
}

class AuthService {
  constructor() {
    this.setAuthorizationHeader();
  }

  async authorize(credentials: {
    email: string;
    password: string;
  }): Promise<Boolean> {
    try {
      const response = await axios.post<{ token: string }>(
        conf.BACKEND + "auth/login",
        credentials
      );
      localStorage.setItem("token", response.data.token);
      this.setAuthorizationHeader();
      const userData = await this.getCurrentUserDetails();
      this.setUserDataInStore(userData);
      return true;
    } catch (error) {
      return false;
    }
  }

  async register(credentials: {
    email: string;
    password: string;
  }): Promise<boolean> {
    try {
      const response = await axios.post(
        conf.BACKEND + "auth/register",
        credentials
      );
      console.log(response);
      return response.status === 204;
    } catch (error) {
      return false;
    }
  }

  async logout(): Promise<void> {
    localStorage.removeItem("token");
    useAuthStore.getState().setToken(null, "", 0, false, []);
  }

  private async getCurrentUserDetails(): Promise<UserData> {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await axios.get<UserData>(conf.BACKEND + "auth/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      }
    } catch (error) {
      console.log("Failed to get current user details");
      this.logout();
    }
    throw new Error("No token found"); // Throw an error if token doesn't exist
  }

  private setUserDataInStore(userData: UserData): void {
    const { email, balance, isEmailConfirmed, roles } = userData;
    useAuthStore
      .getState()
      .setToken(
        localStorage.getItem("token"),
        email,
        balance,
        isEmailConfirmed,
        roles
      );
  }

  setAuthorizationHeader(): void {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }

  async updateCurrentUserDetails(): Promise<void> {
    try {
      const userData = await this.getCurrentUserDetails();
      this.setUserDataInStore(userData);
    } catch (error) {
      console.log("Authorization failed");
      this.logout();
    }
  }

  async resendConfirmationEmail(): Promise<void> {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await axios.post<UserData>(
          conf.BACKEND + "auth/resend",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {}
  }

  async confirmEmail(confirmationToken: string): Promise<void> {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        await axios.post(
          conf.BACKEND + "auth/confirm",
          {
            token: confirmationToken,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {}
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      await axios.post(conf.BACKEND + "auth/forgotPassword", {
        email,
      });
    } catch (error) {}
  }

  async resetPassword(email: string, resetPasswordToken: string): Promise<void> {
    try {
      await axios.post(conf.BACKEND + "auth/resetPassword", {
        email,
        token: resetPasswordToken,
      });
    } catch (error) {}
  }
}

const AuthServiceInstance = new AuthService();
AuthServiceInstance.setAuthorizationHeader();
AuthServiceInstance.updateCurrentUserDetails();

export default AuthServiceInstance;
