import {create} from 'zustand';
import OfflineService from '../../api/admin/offlineService';

interface OfflineServiceState {
  orders: any[];
  order: any;
  clearOrders: () => Promise<void>;
  fetchOrders: (mode: string) => Promise<void>;
  fetchOrder: (id: number) => Promise<void>;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}

const useOfflineServiceStore = create<OfflineServiceState>((set) => ({
  orders: [],
  clearOrders: async() => {
      const orders = [] as any[];
      set({orders})
  },
  fetchOrders: async (mode: string) => {
    const offlineService = new OfflineService();
    try {
      const orders = await offlineService.orders(mode);
      set({ orders });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  },
  order: undefined,
  fetchOrder: async (id) => {
    const offlineService = new OfflineService();
    try {
      const order = await offlineService.orderInfo(id);
      set({ order });
    } catch (error) {
      console.error('Error fetching order info:', error);
    }
  },
  selectedFile: null,
  setSelectedFile: (file) => set({ selectedFile: file }),  
}));

export default useOfflineServiceStore;