import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminOfflineSettings() {
    return(<>
        <AdminUploadConfigMain type="offlinesettings" fileName="OfflineSettings.json" 
              label="Offline Settings" path={ConstantRoutes.adminOfflineSettings}/>
    </>)
}

export default AdminOfflineSettings;