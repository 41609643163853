import { create } from "zustand";
import NewsService, { NewsRootResult } from "../api/newsService";

interface NewsStore {
  news: NewsRootResult[];
  fetchNews: () => Promise<void>;
}

export const useNewsStore = create<NewsStore>((set) => ({
  news: [],
  fetchNews: async () => {
    const newsService = new NewsService();
    try {
      const news = await newsService.getNews();
      set({ news });
    } catch (error) {}
  }   
}));
