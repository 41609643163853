import { create } from "zustand";
import EmailSettingsService, { EmailSettingsResult } from "../../api/admin/emailSettingsService";

interface EmailStore {
  settings: EmailSettingsResult | undefined;
  fetchSettings: () => Promise<void>;
  updateFrom: (newFrom: string) => void;
  updatePassword: (newPassword: string) => void;
  updateFromName: (newFromName: string) => void;
  updateSmtpPort: (newSmtpPort: number) => void;
  updateSmtpAddress: (newSmtpAddress: string) => void;
  updateConfirmSubject: (newConfirmSubject: string) => void;
  updateConfirmMessage: (newConfirmMessage: string) => void;
  updateIsSecure: (newIsSecure: boolean) => void;
  updateForgotPasswordMessage: (newForgotPasswordMessage: string) => void;
  updateForgotPasswordSubject: (newForgotPasswordSubject: string) => void;
  updateResetPasswordMessage: (newResetPasswordMessage: string) => void;
  updateResetPasswordSubject: (newResetPasswordSubject: string) => void;
  testEmail: string;
  updateTestEmail: (newTestEmail: string) => void;
  testResponse: string;
  updateTestResponse: (newTestResponse: string) => void;
}

export const useEmailStore = create<EmailStore>((set) => ({
  settings: undefined,
  fetchSettings: async () => {
    const emailService = new EmailSettingsService();
    try {
      const settings = await emailService.getEmailSettings();
      set({ settings });
    } catch (error) {}
  },
  updateFrom: (newFrom: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, from: newFrom } : undefined,
    }));
  },
  updatePassword: (newPassword: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, password: newPassword } : undefined,
    }));
  },
  updateFromName: (newFromName: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, fromName: newFromName } : undefined,
    }));
  },
  updateSmtpPort: (newSmtpPort: number) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, smtpPort: newSmtpPort } : undefined,
    }));
  },
  updateSmtpAddress: (newSmtpAddress: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, smtpAddress: newSmtpAddress } : undefined,
    }));
  },
  updateConfirmSubject: (newConfirmSubject: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, comfirmSubject: newConfirmSubject } : undefined,
    }));
  },
  updateConfirmMessage: (newConfirmMessage: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, confirmMessage: newConfirmMessage } : undefined,
    }));
  },
  updateIsSecure: (newIsSecure: boolean) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, isSecure: newIsSecure } : undefined,
    }));
  },
  updateForgotPasswordMessage: (newForgotPasswordMessage: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, forgotPasswordMessage: newForgotPasswordMessage } : undefined,
    }));
  },
  updateForgotPasswordSubject: (newForgotPasswordSubject: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, forgotPasswordSubject: newForgotPasswordSubject } : undefined,
    }));
  },
  updateResetPasswordMessage: (newResetPasswordMessage: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, resetPasswordMessage: newResetPasswordMessage } : undefined,
    }));
  },
  updateResetPasswordSubject: (newResetPasswordSubject: string) => {
    set((state) => ({
      settings: state.settings ? { ...state.settings, resetPasswordSubject: newResetPasswordSubject } : undefined,
    }));
  },
  testEmail: '',
  updateTestEmail: (newTestEmail: string) => {
    set({testEmail: newTestEmail})
  },
  testResponse: '',
  updateTestResponse: (newTestResponse: string) => {
    set({testResponse: newTestResponse});
  }
}));
