import { create } from "zustand";

interface AppState {
  modalOpen: boolean;
  message: string;
  setModalOpen: (isOpen: boolean) => void;
  setMessage: (message: string) => void;
  confirmAction: any;
  setConfirmAction: (confirmAction: any) => void;
}

export const useModalConfirmActionStore = create<AppState>((set) => ({
  modalOpen: false,
  message: '',
  setModalOpen: (isOpen) => set({ modalOpen: isOpen }),
  setMessage: (message) => set({message}),
  confirmAction: () => {},
  setConfirmAction: (confirmAction) => set({confirmAction})
}));