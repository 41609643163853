import { useTranslation } from "react-i18next";
import useLeftMenuStore from "../../../stores/leftMenuStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import Dropdown from "../../dropdown/dropdown";
import { useAuthStore } from "../../../stores/authStore";
import AuthServiceInstance from "../../../api/authServices";
import { useNavigate } from "react-router-dom";
import { useModalAuthorizationStore } from "../../../stores/modalAuthorizationStore";

const MainMdMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useLeftMenuStore();
  const { isAuthenticated, authorizedEmail } = useAuthStore();

  const handleItemClick = (route: string) => {
    setIsOpen(false);
    navigate(`#${route}`);
  };

  return (
    <div
      className={`fixed top-10 left-0 w-full h-full flex flex-col items-center justify-center bg-[#10121B] z-49
     md:hidden ${isOpen ? "" : "hidden"} `}
    >
      <div className="flex flex-col items-center text-center justify-evenly">
        <div className="py-3">
          <a          
            href={`#${ConstantRoutes.tutorials}`}
            onClick={() => handleItemClick(ConstantRoutes.tutorials)}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("Tutorials")}
          </a>
        </div>
        <div className="py-3">
          <a
            href={`#${ConstantRoutes.pricing}`}
            onClick={() => handleItemClick(ConstantRoutes.pricing)}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("Pricing")}
          </a>
        </div>
        <div className="py-3">
          <a
            href={`#${ConstantRoutes.news}`}
            onClick={() => handleItemClick(ConstantRoutes.news)}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("News")}
          </a>
        </div>
        <div className="py-3">
          <a
            href={`#${ConstantRoutes.faq}`}
            onClick={() => handleItemClick(ConstantRoutes.faq)}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("FAQ")}
          </a>
        </div>
        <div className="py-3">
          <a
            href={ConstantRoutes.externalDamos}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("Damos")}
          </a>
        </div>
        <div className="py-3">
          <a
            href={ConstantRoutes.externalOriginal}
            className="text-textsecondary transition-all duration-300 hover:text-white text-3xl"
          >
            {t("Original Files")}
          </a>
        </div>
      </div>

      <div className="w-full p-8">
        {isAuthenticated() && (
          <div className="p-4">
            <div className="flex items-center bg-content3 p-4 rounded-xl">
              <p className="bg-content2 p-2 text-xl rounded-xl w-12 h-12 text-center">
                N
              </p>

              <Dropdown
                items={[
                  { id: "profile", label: t("Profile") },
                  { id: "quit", label: t("Exit") },
                ]}
                onSelect={function (item: { id: string; label: string }): void {
                  if (item.id === "quit") {
                    AuthServiceInstance.logout();
                    navigate(ConstantRoutes.main);
                  }
                }}
                caption={authorizedEmail ?? "ERROR"}
                reverseDirection={true}
              />
            </div>
          </div>
        )}

        {!isAuthenticated() && (
          <>
            <button
              className="border border-primary rounded-md w-full h-10 items-center 
                      justify-center bg-primary hover:animate-pulse transition duration-300 ease-in-out mr-4
                      mb-4"
              onClick={() =>
                useModalAuthorizationStore.setState({
                  modalOpen: true,
                  activeTab: "login",
                })
              }
            >
              {t("Sign In")}
            </button>
            <button
              className="rounded-md w-full h-10 items-center 
                      justify-center hover:bg-primary transition duration-300 ease-in-out"
              onClick={() =>
                useModalAuthorizationStore.setState({
                  modalOpen: true,
                  activeTab: "register",
                })
              }
            >
              {t("Sign Up")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MainMdMenu;
