import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminSwid() {
    return(<>
        <AdminUploadConfigMain type="swid" fileName="SwidSources.csv" 
              label="Swid" path={ConstantRoutes.adminSwid}/>
    </>)
}

export default AdminSwid;