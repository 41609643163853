import { create } from 'zustand';
import { FirmwareInfo, FirmwareProcessResult, Patch } from '../models/firmware';

interface UploadStore {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  firmwareInfo: FirmwareInfo | null;
  setFirmwareInfo: (info: FirmwareInfo | null) => void;
  checkedOnlinePatches: Patch[];
  setCheckedOnlinePatches: (patches: Patch[]) => void;
  checkedOfflinePatches: Patch[];
  setCheckedOfflinePatches: (patches: Patch[]) => void;
  checkedTgFlashPatches: Patch[];
  setCheckedTgFlashPatches: (patches: Patch[]) => void;
  maxPatchPrice: number | null;
  setMaxPatchPrice: (maxPrice: number | null) => void;
  plusPatchPrice: number | null;
  setPlusPatchPrice: (plusPrice: number | null) => void;
  fileUploaded: boolean | null;
  setFileUploaded: (uploaded: boolean | null) => void;
  stockFile: boolean | null;
  setStockFile: (stockFile: boolean | null) => void;
  fixFile: boolean | null;
  setFixFile: (fixFile: boolean | null) => void;
  comment: string | undefined;
  setComment: (comment: string | undefined) => void;
  firmwareProcessResult: FirmwareProcessResult | null;
  setFirmwareProcessResult: (processResult: FirmwareProcessResult | null) => void;
}

export const useUploadStore = create<UploadStore>((set) => ({
  selectedFile: null,
  setSelectedFile: (file) => set({ selectedFile: file }),
  firmwareInfo: null,
  setFirmwareInfo: (info) => set({firmwareInfo: info}),
  checkedOnlinePatches: [],
  setCheckedOnlinePatches: (patches) => set({ checkedOnlinePatches: patches }), 
  checkedOfflinePatches: [],
  setCheckedOfflinePatches: (patches) => set({ checkedOfflinePatches: patches }),   
  checkedTgFlashPatches: [],
  setCheckedTgFlashPatches: (patches) => set({ checkedTgFlashPatches: patches }),   
  maxPatchPrice: null,
  setMaxPatchPrice: (maxPrice) => set({maxPatchPrice: maxPrice}),
  plusPatchPrice: null,
  setPlusPatchPrice: (plusPrice) => set({plusPatchPrice: plusPrice}),
  fileUploaded: null,
  setFileUploaded: (uploaded) => set({fileUploaded: uploaded}),
  stockFile: null,
  setStockFile: (change) => set({stockFile: change}),  
  fixFile: null,
  setFixFile: (change) => set({fixFile: change}),
  comment: undefined,
  setComment: (comment) => set({comment}),
  firmwareProcessResult: null,
  setFirmwareProcessResult: (processResult) => set({firmwareProcessResult: processResult}),
}));