import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
} from "axios";
import getConfig from "../../config/configUtil";

const conf = getConfig;

interface UploadOptions {
  onProgress?: (progress: number) => void;
}

export default class UploadConfigService {
  async uploadFile(
    file: File,
    type: string,
    options?: UploadOptions
  ): Promise<string> {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (options?.onProgress) {
          const progress = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 90
          );
          options.onProgress(progress);
        }
      },
    };

    try {
      const response: AxiosResponse<string> = await axios.post(
        conf.BACKEND + "admin/configs/" + type + "/upload",
        formData,
        config
      );
      console.log("File uploaded successfully:", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios Error:", error.message);
      } else {
        console.error("Error uploading file:", error);
      }
      throw error;
    }
  }

  async downloadFile(type: string, fileName: string): Promise<void> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "admin/configs/" + type + "/download",
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
      throw new Error("Failed to download file");
    }
  }
}
