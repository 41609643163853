import { useAuthStore } from "../../stores/authStore";
import Dropdown from "../dropdown/dropdown";
import { useTranslation } from "react-i18next";

interface Language {
  id: string;
  label: string;
}

function LanguageSelector() {
  const { i18n } = useTranslation();
  const { setLang } = useAuthStore();

  const currentLanguageCode = i18n.language;

  const languageLabels: { [key: string]: string } = {
    ru: "Ru",
    en: "En",
  };

  const caption = languageLabels[currentLanguageCode];

  const handleLanguageChange = (selectedLanguage: Language): void => {
    i18n.changeLanguage(selectedLanguage.id);
    setLang(selectedLanguage.id);
  };

  const languageOptions: Language[] = [
    { id: "ru", label: "Ru" },
    { id: "en", label: "En" },
  ];

  return (
    <>
      <Dropdown
        items={languageOptions}
        onSelect={handleLanguageChange}
        caption={caption}
      />
    </>
  );
}

export default LanguageSelector;
