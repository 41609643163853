import React from "react";
import { Attachment } from "react-huge-icons/outline";
import { useTranslation } from "react-i18next";
import SupportTicketRightPart from "./supportTicketRightPart";
import SupportChat from "./supportChat";

const SupportTicket: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-grow flex max-md:flex-col">
        <div className="w-full md:hidden mb-6">
          <SupportTicketRightPart />
        </div>

        <SupportChat />

        <div className="lg:w-5/12 xl:w-1/3 h-full pl-6 max-md:hidden">
          <SupportTicketRightPart />
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
