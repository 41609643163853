import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminGroups() {
    return(<>
        <AdminUploadConfigMain type="groups" fileName="Groups.json" 
              label="Groups" path={ConstantRoutes.adminGroups}/>
    </>)
}

export default AdminGroups;