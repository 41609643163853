import axios, { AxiosResponse } from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface OfflineSettingsRootResult {
  lang: string;
  records: OfflineSettingsResult[];
}

export interface OfflineSettingsResult {
  header: string;
  description: string;
}

class OfflineSettingsService {
  async getOfflineSettings(): Promise<OfflineSettingsRootResult[]> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "offlinesettings/all");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch Offline settings");
    }
  }
}

export default OfflineSettingsService;
