import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import AdminSupportItem from './adminSupportRecord';
import { convertUTCDateToLocalDateString } from '../../../helpers/dateHelper';
import useBreadcrumbsStore from '../../../stores/breadcrumbStore';
import { ConstantRoutes } from '../../../core/constantRoutes';
import useAdminSupportStore from '../../../stores/admin/adminSupportStore';
import { ChatResult } from '../../../api/admin/adminSupportService';

const AdminSupportCenter: React.FC = () => {
  const { t } = useTranslation();
  const { chats, fetchChats } = useAdminSupportStore();
  const {addBreadcrumb, clearBreadcrumbs} = useBreadcrumbsStore();  

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({label: 'Support Center', path: ConstantRoutes.adminSupportCenter})

    fetchChats();    
  }, []); 

  return (
    <div className="relative flex-1 flex flex-col">

      {chats.map((chat: ChatResult, index: number) => (
        <AdminSupportItem title={chat.title} date={convertUTCDateToLocalDateString(chat.createdOn.toString())} 
          showDot={chat.userNewMessagesCount > 0} ticket={chat.userFileId}/>
      ))}
    </div>
  );
};

export default AdminSupportCenter;