import { create } from 'zustand';

interface AuthState {
  email: string;
  password: string;
  repeatPassword: string;
  roles: string[],
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setRepeatPassword: (repeatPassword: string) => void;
  token: string | null;
  setToken: (token: string | null, email: string, balance: number, isEmailConfirmed: boolean, roles: string[]) => void;
  isAuthenticated: () => boolean;
  authorizedEmail: string | null;
  balance: number | null;
  setBalance: (balance: number | null) => void;
  lang: string;
  setLang: (lanng: string) => void;
  message: string;
  setMessage: (message: string) => void;
  isSuccess: boolean;
  setIsSuccess: (isSuccess: boolean) => void;
  isEmailConfirmed: boolean;
}

export const useAuthStore = create<AuthState>((set) => ({
  email: '',
  password: '',
  repeatPassword: '',
  roles: [],
  setEmail: (email) => set((state) => ({ ...state, email })),
  setPassword: (password) => set((state) => ({ ...state, password })),
  setRepeatPassword: (repeatPassword) => set((state) => ({ ...state, repeatPassword })),
  token: null,
  setToken: (token, authorizedEmail, balance, isEmailConfirmed, roles) => set((state) => ({ ...state, token, authorizedEmail, balance, isEmailConfirmed, roles })),
  isAuthenticated: () => !!localStorage.getItem('token'),
  authorizedEmail: null,
  balance: null,
  setBalance: (balance) => set({ balance }),
  lang: 'en',
  setLang: (lang) => set({lang}),
  message: '',
  setMessage: (message) => set({message}),
  isSuccess: false,
  setIsSuccess: (isSuccess) => set({isSuccess}),
  isEmailConfirmed: false
}));