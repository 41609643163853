import React, { useEffect, DragEvent, ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import OfflineService from "../../../api/admin/offlineService";

const AdminTableServiceRecordUploadFile: React.FC = () => {
  const { t } = useTranslation();
  const { fetchOrder, selectedFile, setSelectedFile } =
    useOfflineServiceStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const { file } = useParams<{ file: string }>();
  const offlineService = new OfflineService();

  const [highlighted, setHighlighted] = useState(false);
  const [inWork, setInWork] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileInfo, setUploadedFileInfo] = useState("");

  useEffect(() => {
    setInWork(false);
    setSelectedFile(null);
    setUploadedFileInfo("");
    setUploadProgress(0);
  }, []);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const uploadFile = async (binaryFile: File) => {
    if (binaryFile) {
      try {
        var uploadedFileInfo = await offlineService.uploadFile(
          binaryFile,
          Number(file),
          {
            onProgress: (progress) => {
              setUploadProgress(progress);
            },
          }
        );
        setInWork(true);
        setSelectedFile(null);
        setUploadedFileInfo(uploadedFileInfo);
        setUploadProgress(0);
      } catch (error) {
        // Handle error
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case when no file is selected
      console.error("No file selected for upload.");
    }
  };

  return (
    <>
      <div
        className={`flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72 mt-4
                ${highlighted ? "border-primary bg-content2" : ""}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {selectedFile && (
          <div className="mt-4 relative">
            <div className="relative z-10">
              <h1 className="text-2xl pb-4"> {t("Upload progress")}</h1>
              <p className="hidden md:block text-textsecondary text-sm pb-4">
                {t("Wait until the file is uploaded")}
              </p>

              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-tablebg2">
                <div
                  style={{ width: `${uploadProgress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-primary transition-width duration-500 ease-in-out"
                ></div>
              </div>
            </div>
          </div>
        )}

        {!selectedFile && (
          <div className="text-center">
            <h1 className="text-2xl pb-4">{t("Upload a new file")}</h1>
            <p className="hidden md:block text-textsecondary pb-4">
              {t("Drag the file to the highlighted area and click upload file")}
            </p>
            <div className="flex justify-center">
              <div className="py-5">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  id="fileInput"
                />
                <label
                  htmlFor="fileInput"
                  className="text-center cursor-pointer py-5 px-14 rounded-lg bg-primary"
                >
                  {t("Upload")}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminTableServiceRecordUploadFile;
