import axios, {
  AxiosResponse,
} from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface DifferenceResult {
    original: number;
    afterTuning: number;
    difference: number;
}

export interface ChangesResult {
    name: string;
    power: DifferenceResult;
    torque: DifferenceResult;
}

class RemappingService {
  async getSeries(): Promise<string[]> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "remapping/series"
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch series");
    }
  }

  async getGenerations(series: string): Promise<string[]> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "remapping/generation/" + series
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch generation");
    }
  }

  async getEngines(series: string, generation: string): Promise<string[]> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "remapping/engine/" + series + "/" + generation
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch engines");
    }
  }

  async getEcus(series: string, generation: string, engine: string): Promise<string[]> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "remapping/ecu/" + series + "/" + generation + "/" + engine
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch ecus");
    }
  }  

  async getChanges(series: string, generation: string, engine: string, ecu: string): Promise<ChangesResult[]> {
    try {
      const response: AxiosResponse = await axios.get(
        conf.BACKEND + "remapping/changes/" + series + "/" + generation + "/" + engine + "/" + ecu
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch changes");
    }
  }    
}

export default RemappingService;
