import React from "react";
import useSupportStore from "../../stores/chatStore";
import { convertUTCDateToLocalDateString } from "../../helpers/dateHelper";

const SupportTicketFileInfo: React.FC = () => {
  const { fileInfo } = useSupportStore();

  return (
    <>
      <div className="mt-4 p-5 border rounded-xl border-tableline grid grid-cols-[20%_80%] gap-x-4 gap-y-3">
        <p className="md:text-sm max-xl:text-lg text-textsecondary">Series</p>
        <p className="md:text-sm max-xl:text-lg text-right overflow-hidden whitespace-normal pr-4">{fileInfo?.series}</p>
        <p className="md:text-sm max-xl:text-lg text-textsecondary">ECU</p>
        <p className="md:text-sm max-xl:text-lg text-right overflow-hidden whitespace-normal pr-4">{fileInfo?.ecu}</p>
        <p className="md:text-sm max-xl:text-lg text-textsecondary">Engine</p>
        <p className="md:text-sm max-xl:text-lg text-right overflow-hidden whitespace-normal pr-4">{fileInfo?.engine}</p>
        <p className="md:text-sm max-xl:text-lg text-textsecondary">Software</p>
        <p className="md:text-sm max-xl:text-lg text-right overflow-hidden whitespace-normal pr-4">{fileInfo?.software}</p>
        <p className="md:text-sm max-xl:text-lg text-textsecondary">Uploaded</p>
        <p className="md:text-sm max-xl:text-lg text-right pr-4">{convertUTCDateToLocalDateString(fileInfo?.uploaded)}</p>  
      </div>
    </>
  );
};

export default SupportTicketFileInfo;
