import axios, { AxiosResponse } from "axios";
import getConfig from "../../config/configUtil";

const conf = getConfig;

export interface UsersRootResult {
  user: UsersResult;
}

export interface UsersResult {
  id: string;
  email: string;
  isEmailConfirmed: Boolean;
  coins: number;
}

class UsersService {
  async getUsers(): Promise<UsersRootResult[]> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "admin/user/users");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch Users");
    }
  }

  async addTokens(userId: string) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/user/add/" + userId);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to add tokens");
    }
  }

  async removeTokens(userId: string) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/user/remove/" + userId);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to remove tokens");
    }
  }

  async deleteUser(userId: string) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/user/delete/" + userId);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to delete user");
    }
  }

  async confirmEmail(userId: string) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/user/confirmEmail/" + userId);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to confirm email");
    }
  }

  async resetPassword(userId: string, newPassword: string) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/user/resetPassword/" + userId, {newPassword: newPassword});
    } catch (error) {
      console.log(error);
      throw new Error("Failed to reset password");
    }
  }
}

export default UsersService;
