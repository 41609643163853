import { useState } from "react";
import { PlusThin, MinusThin } from "react-huge-icons/solid";

type FaqProps = {
  header: string;
  fullText: string;
};

function FaqItem({ header, fullText }: FaqProps) {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return (
    <>
      <div className="bg-content3 rounded-xl flex flex-col my-4">
        <div className="flex flex-row p-5 justify-between items-center">
          <p className="text-xl font-medium">{header}</p>

          <div
            className="flex justify-center items-center w-10 h-10 bg-content2 rounded-lg cursor-pointer
              hover:bg-primary transition duration-300"
            onClick={toggleDescription}
          >
            <MinusThin
              className={`transition-all duration-300 ${
                showDescription ? "opacity-100 w-6 h-6 " : "opacity-0 w-0 h-0"
              }`}
            />

            <PlusThin
              className={`transition-all duration-300 ${
                !showDescription ? "opacity-100 w-6 h-6 " : "opacity-0 w-0 h-0"
              }`}
            />
          </div>
        </div>
        <div className="flex flex-row content-start">
          <p
            className={`text-textsecondary px-5 w-8/12 text-left transition-all duration-300 ${
              showDescription
                ? "opacity-100 max-h-full pb-5"
                : "opacity-0 max-h-0"
            }`}
          >
            {fullText}
          </p>
        </div>
      </div>
    </>
  );
}

export default FaqItem;
