import axios, { AxiosResponse } from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface NewsRootResult {
  lang: string;
  records: NewsResult[];
}

export interface NewsResult {
  header: string;
  description: string;
  published: string;
}

class NewsService {
  async getNews(): Promise<NewsRootResult[]> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "news/all");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch News");
    }
  }
}

export default NewsService;
