import axios, { AxiosResponse } from "axios";
import getConfig from "../config/configUtil";

const conf = getConfig;

export interface FaqRootResult {
  lang: string;
  records: FaqResult[];
}

export interface FaqResult {
  header: string;
  description: string;
}

class FaqService {
  async getFaqs(): Promise<FaqRootResult[]> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "faq/all");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch FAQs");
    }
  }
}

export default FaqService;
