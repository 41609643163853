import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './../locales/en/translation.json';
import translationRU from './../locales/ru/translation.json';

const resources: Record<string, { translation: Record<string, string> }> = {
  en: {
    translation: translationEN as Record<string, string>
  },
  ru: {
    translation: translationRU as Record<string, string>
  }
};

export function initializeI18n() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: resources,
      debug: false,
      fallbackLng: 'en',
      supportedLngs: ['en', 'ru'],
      interpolation: {
        escapeValue: false
      }
    });

  return i18n;
}

export default initializeI18n;