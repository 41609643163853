import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import { useEffect, useRef, useState } from "react";

type RemappingDropdownProps = {
  items: string[];
  onSelect: (item: string) => void;
  caption: string;
};

const RemappingDropdown: React.FC<RemappingDropdownProps> = ({
  items,
  onSelect,
  caption,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    if (items.length > 0) setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (item: string) => {
    onSelect(item);
    toggleDropdown();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      <div
        ref={dropdownRef}
        className="relative inline-block text-left w-full max-w-full content-center"
      >
        <div className="">
          <div className={`flex 
          ${items.length > 0 ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={toggleDropdown}>
            <p className="pl-4 w-full">{caption}</p>
            <ChevronDownIcon
              className={`w-6 transition-all duration-300 ${
                isOpen ? "rotate-180" : "rotate-0"
              }
            ${isOpen ? "text-primary" : "text-textsecondary"}`}
            />
          </div>
        </div>

        <div
          className={`origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-content1 
        focus:outline-none z-50
        transition-opacity duration-300 ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
        >
          {isOpen && (
            <div
              className="p-2"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {items.map((item) => (
                <p
                  key={item}
                  onClick={() => handleItemClick(item)}
                  className="block px-4 py-2 bg-content1 hover:bg-primary transition duration-300 ease-in-out cursor-pointer rounded-md"
                  role="menuitem"
                >
                  {item}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RemappingDropdown;
