import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../stores/authStore";
import AuthServiceInstance from "../../api/authServices";
import { useNavigate } from "react-router-dom";
import { useModalAuthorizationStore } from "../../stores/modalAuthorizationStore";
import { ConstantRoutes } from "../../core/constantRoutes";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";
import { useState } from "react";

const LoginForm: React.FC = () => {
  const { email, password, token, setEmail, setPassword, isAuthenticated } =
    useAuthStore();
  const setModalForgotPasswordOpen = useModalRefillBalanceStore(
    (state) => state.setModalForgotPasswordOpen
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState(String);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setMessage('');
    e.preventDefault();
    const authorizationResult = await AuthServiceInstance.authorize({ email, password });
    if (authorizationResult && token !== undefined) {
      setEmail("");
      setPassword("");
      useModalAuthorizationStore.setState({ modalOpen: false });
      console.log(isAuthenticated());
      navigate(ConstantRoutes.fileProcessing);
    }
    if (!authorizationResult){
      setMessage('Failed authorization attempt. Please check that the login and password entered are correct.');
      setPassword('');
    }
  };

  const handleForgotPassword = async () => {
    useModalAuthorizationStore.setState({ modalOpen: false });
    setModalForgotPasswordOpen(true);
  };

  const isDisabled =
    email.trim() === "" ||
    password.trim() === "" ||
    password.length < 6 ||
    email.indexOf("@") < 0;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder={t("Email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block mb-4 w-full  outline-none px-2 py-1 bg-content3 rounded-md p-2"
        />
        <input
          type="password"
          placeholder={t("Password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block mb-4 w-full  outline-none px-2 py-1 bg-content3 rounded-md p-2"
        />
        <button
          type="submit"
          className={`bg-primary rounded-md px-4 py-2 ${
            isDisabled ? "opacity-10 cursor-not-allowed" : "opacity-100"
          } transition duration-300 ease-in-out`}
          disabled={isDisabled}
        >
          {t("Login")}
        </button>
      </form>
      <div className="p-3">
        <button
          className={`bg-secondary rounded-md px-4 py-2 transition duration-300 ease-in-out`}
          onClick={handleForgotPassword}
        >
          {t("Forgot password")}
        </button>
      </div>

      {(message.length > 1) &&
        <h1 className='p-2 text-lg text-danger'>{t(message)}</h1>
      }
    </>
  );
};

export default LoginForm;
