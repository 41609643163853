import { User, Coin, Logout, CopyCheck } from "react-huge-icons/outline";

import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import InstructionStep from "./instructionStep";
import { useTranslation } from "react-i18next";
import { ConstantRoutes } from "../../../core/constantRoutes";

const DmeTechInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="bg-cover bg-center flex flex-col
        md:bg-[linear-gradient(to_top,rgba(2,8,19,1),rgba(0,0,0,0)),url('/img/serjan-midili.jpg')]"
      >
          <div className="place-self-center max-w-screen-2xl w-full md:my-12 md:mb-28 flex justify-center flex-row">
            <div className="md:w-1/3 max-md:w-full">
              <h1 className="text-5xl font-bold pb-8 max-md:p-8">
                {t('Improve your BMW with')}{" "}
                <span className="text-primary">DME Tech</span>
              </h1>
              <h2 className="text-textsecondary text-lg max-md:p-8">
                {t('Fast online file processing and personal settings from our engineers')}
              </h2>
            </div>
            <div className="md:w-2/3 max-md:hidden"></div>
          </div>

          <div className="overflow-hidden md:hidden">
            <img
              className="transform scale-150 h-auto max-w-full"
              src="/img/serjan-midili.jpg"
              alt=""
            />
          </div>

          <div className="place-self-center max-w-screen-2xl w-full my-12 max-md:p-4">
            <h2 className="text-2xl font-bold pb-6">
            {t('Four simple steps')}
            </h2>

            <div className="flex items-center justify-between max-md:flex-col max-md:w-full">
              <InstructionStep
                icon={User}
                text={t('Register on the site')}
                isRotated={false}
                link={ConstantRoutes.register}
              />

              <ChevronDownIcon className="w-6 h-6 text-primary -rotate-90 max-md:hidden" />

              <InstructionStep
                icon={Coin}
                text={t('Top up your balance')}
                isRotated={false}
                link={ConstantRoutes.topUpBalance}
              />

              <ChevronDownIcon className="w-6 h-6 text-primary -rotate-90 max-md:hidden" />

              <InstructionStep
                icon={Logout}
                text={t('Upload your file')}
                isRotated={true}
                link={ConstantRoutes.fileProcessing}
              />

              <ChevronDownIcon className="w-6 h-6 text-primary -rotate-90 max-md:hidden" />

              <InstructionStep
                icon={CopyCheck}
                text={t('Download the tuning file')}
                isRotated={false}
                link={ConstantRoutes.orderHistory}
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default DmeTechInfo;
