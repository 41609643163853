import { Outlet } from "react-router-dom";
import { useAuthStore } from "../../stores/authStore";
import AdminLayout from "./adminLayout";

function Admin() {
  const { roles } = useAuthStore();

  return (
    <>
      {roles.includes("admin") && (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      )}
    </>
  );
}

export default Admin;
