import { create } from "zustand";

interface AppState {
  modalOpen: boolean;
  activeTab: string;
  setModalOpen: (isOpen: boolean) => void;
  setActiveTab: (activeTab: string) => void;
}

export const useModalAuthorizationStore = create<AppState>((set) => ({
  modalOpen: false,
  activeTab: 'login',
  setModalOpen: (isOpen) => set({ modalOpen: isOpen }),
  setActiveTab: (activeTab: string) => set({activeTab})
}));