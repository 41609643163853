const RadialGradient: React.FC = () => {
  return (
    <>
      <div className="absolute h-full w-126 pointer-events-none z-0 max-md:hidden
      bg-[radial-gradient(circle_at_0%_50%,#009EE011,#009EE000,#009EE000)]"></div>
    </>
  );
};

export default RadialGradient;
