import React from "react";
import AdminTableServiceRecord from "../adminTableServiceMain/adminTableServiceRecord";

const AdminOnlineServiceRecord: React.FC = () => {
  return(
      <>
          <AdminTableServiceRecord label="Admin online service" path="onlineservice" />
      </>
  )
}

export default AdminOnlineServiceRecord;
