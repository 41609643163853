import React from "react";
import { useParams } from "react-router-dom";
import AdminSupportChat from "../adminSupportCenter/adminSupportChat";

const AdminTableServiceRecordChat: React.FC = () => {

  const { file } = useParams<{ file: string }>();
  return (
    <>
      <AdminSupportChat ticket={Number(file)} />
    </>
  );
};

export default AdminTableServiceRecordChat;
