import { TrackPlay } from "react-huge-icons/solid";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useTranslation } from "react-i18next";

const Tutorials: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col z-10" id={ConstantRoutes.tutorials}>
        <div className="max-w-screen-2xl w-full place-self-center text-center pb-10 md:pt-40 max-md:pt-10">
          <h1 className="text-4xl font-bold">{t('Tutorials')}</h1>
        </div>

        <div className="max-w-screen-2xl w-full place-self-center flex md:flex-row max-md:flex-col max-md:p-4 justify-between overflow-hidden">
          <div>
            <div
              className="relative rounded-xl md:w-[420px] max-md:w-[350px] h-80 overflow-hidden bg-cover"
            >
              <iframe width="420" height="320" src="https://www.youtube.com/embed/algay9l68js" 
              title="SingUp &amp; Top-up" frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <p className="py-3 text-center font-medium">
              {t('Registration and top-up of the balance')}
            </p>
          </div>
          <div>
            <div
              className="relative rounded-xl md:w-[420px] max-md:w-[350px] h-80 overflow-hidden bg-cover"
            >
              <iframe width="420" height="320" src="https://www.youtube.com/embed/jC0yWr4u7Io" 
              title="Online Service" frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <p className="py-3 text-center font-medium">
            {t('Online service')}
            </p>
          </div>
          <div>
            <div
              className="relative rounded-xl md:w-[420px] max-md:w-[350px] h-80 overflow-hidden bg-cover"
            >
              <iframe width="420" height="320" src="https://www.youtube.com/embed/iGIC_h36iMI" 
              title="Offline Service" frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <p className="py-3 text-center font-medium">
            {t('Offline service')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tutorials;
