import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios';
import getConfig from '../config/configUtil';
import { OrderHistoryRecordResult } from './orderHistoryService';

const conf = getConfig;

export interface ChatResult {
    id: number;
    title: string;
    createdOn: Date;
    userNewMessagesCount: number;
    userFileId: number;
}

export interface MessageResult {
    id: number;
    createdOn: Date;
    text: string;
    userId: string;
    chatMessageType: number;
}

interface UploadOptions {
    onProgress?: (progress: number) => void;
}

class SupportService {
    async getChats(): Promise<ChatResult[]> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'chat/chats');
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch chats');
        }
    }

    async getMessages(chatId: number): Promise<MessageResult[]> {
        try{
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'chat/' + chatId);
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error('Failed to fetch messages for chat')
        }
    }

    async orderInfo(chatId: number) : Promise<OrderHistoryRecordResult | null> {
        try {
            const response: AxiosResponse = await axios.get(conf.BACKEND + 'chat/' + chatId + '/fileInfo');
            return response.data;
        } catch (error) {
            return null;
        }
    }

    async sendMessage(chatId: number, message: string): Promise<void> {
        try{
            await axios.post(conf.BACKEND + 'chat/' + chatId + '/send', {message: message});
        } catch (error) {        
        }
    }

    async uploadFile(chatId: number, file: File, options?: UploadOptions): Promise<void> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent : AxiosProgressEvent) => {
                if (options?.onProgress) {
                    const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 1)) * 90);
                    options.onProgress(progress);
                }
            },
        };

        try {
            const response: AxiosResponse<void> = await axios.post(conf.BACKEND + 'chat/' + chatId + '/upload', formData, config);
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios Error:', error.message);
            } else {
                console.error('Error uploading file:', error);
            }
        }
    }

    async downloadMessageFile(chatId: number, id: number): Promise<void> {
        try {
            const fileNameResponse: AxiosResponse = await axios.get(conf.BACKEND + 'chat/' + chatId + '/fileName/' + id, {
                responseType: 'text'
            });

            let filename = fileNameResponse.data;

            const response: AxiosResponse = await axios.get(conf.BACKEND + 'chat/' + chatId + '/file/' + id, {
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
            throw new Error('Failed to download file');
        }
    }        
}

export default SupportService;