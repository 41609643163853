import React, { useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { useTranslation } from "react-i18next";
import AuthServiceInstance from "../../api/authServices";

const EmailNotConfirmed: React.FC = () => {
  const { authorizedEmail } = useAuthStore();
  const { t } = useTranslation();
  const [emailWasSent, setEmailWasSent] = useState(false);

  const resendComfirmationEmail = () => {
    setEmailWasSent(true);
    AuthServiceInstance.resendConfirmationEmail();
  };

  return (
    <div className="flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72">
      <div className="text-center">
        <h2 className="text-2xl font-semibold text-danger mb-4">
          {t("Email Not Confirmed")}
        </h2>
        <p className="text-gray-700 mb-6">
          {t("Please confirm your email address to continue.")}
        </p>
        <p className="text-gray-500 italic">{authorizedEmail}</p>
        {!emailWasSent && (
          <button
            className="mt-4 px-6 py-2 bg-primary  rounded-lg shadow hover:animate-pulse transition duration-300 ease-in-out"
            onClick={resendComfirmationEmail}
          >
            {t("Resend Confirmation Email")}
          </button>
        )}
        {emailWasSent && (
          <h2 className="text-2xl font-semibold text-success mb-4 mt-4">
            {t("Email was sent, please check email")}
          </h2>
        )}
      </div>
    </div>
  );
};

export default EmailNotConfirmed;
