import { ConstantRoutes } from "../../../core/constantRoutes";
import AdminUploadConfigMain from "../adminUploadConfigMain/adminUploadConfigMain";

function AdminAllowedSizes() {
    return(<>
        <AdminUploadConfigMain type="allowedsizes" fileName="FileSizes.json" 
              label="Allowed Sizes" path={ConstantRoutes.adminAllowedSizes}/>
    </>)
}

export default AdminAllowedSizes;