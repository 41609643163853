import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import { useModalAuthorizationStore } from "../../stores/modalAuthorizationStore";

interface AuthorizationModalProps {}

const AuthorizationModal: React.FC<AuthorizationModalProps> = () => {
  const isOpen = useModalAuthorizationStore((state) => state.modalOpen);
  const { activeTab, setActiveTab } = useModalAuthorizationStore();
  const { t } = useTranslation();
  const [isMouseOutside, setIsMouseOutside] = useState(false);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const closeModal = () => {
    useModalAuthorizationStore.setState({ modalOpen: false });
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains("bg-black")) {
      setIsMouseOutside(true);
    } else {
      setIsMouseOutside(false);
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseOutside) {
      const target = event.target as HTMLDivElement;
      if (target.classList.contains("bg-black")) {
        closeModal();
      }
    }
  };

  return (
    <div
      className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-60"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="relative bg-content2 p-8 rounded-lg w-126 h-fit text-center flex flex-col justify-between animate-fade-in">
        <button
          className="absolute top-4 right-4 bg-transparent text-textsecondary text-3xl"
          onClick={() => closeModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="flex-1 flex flex-col justify-center items-center">
          <div className="bg-content2 rounded-lg">
            <div className="flex justify-between mb-8">
              <button
                className={`focus:outline-none ${
                  activeTab === "login" ? "text-primary" : "text-textsecondary"
                }`}
                onClick={() => handleTabChange("login")}
              >
                {t("Login")}
              </button>
              <button
                className={`focus:outline-none ${
                  activeTab === "register"
                    ? "text-primary"
                    : "text-textsecondary"
                }`}
                onClick={() => handleTabChange("register")}
              >
                {t("Register")}
              </button>
            </div>
            {activeTab === "login" ? <LoginForm /> : <RegisterForm />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationModal;
