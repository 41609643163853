import { create } from "zustand";
import FaqService, { FaqRootResult } from "../api/faqService";

interface FaqStore {
  faq: FaqRootResult[];
  fetchFaq: () => Promise<void>;
}

export const useFaqStore = create<FaqStore>((set) => ({
  faq: [],
  fetchFaq: async () => {
    const faqService = new FaqService();
    try {
      const faq = await faqService.getFaqs();
      set({ faq });
    } catch (error) {}
  }   
}));
