import React, { useEffect } from "react";
import SupportTicketFileInfo from "./adminSupportTicketFileInfo";
import { useParams } from "react-router-dom";
import useAdminSupportStore from "../../../stores/admin/adminSupportStore";
import { useTranslation } from "react-i18next";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useNavigate } from "react-router-dom";

const SupportTicketRightPart: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ticket } = useParams<{ ticket: string }>();
  const { fileInfo, fetchFileInfo } = useAdminSupportStore();
  
  useEffect(() => {
    fetchFileInfo(Number(ticket));
  }, []);


  const openOfflineService = () => {
    navigate(ConstantRoutes.adminOfflineService + "/work/" + ticket)
  }

  return (
    <>
      <p className="text-xl">{t('File info')}</p>

      <SupportTicketFileInfo />

      <p className="mt-5 text-xl">Option</p>

      <div className="mt-4 p-5 border rounded-xl border-tableline flex">
        <div className="flex flex-wrap">
        {fileInfo?.options !== undefined &&
          fileInfo.options.map((option: any, subindex: number) => (
            <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
              {option}
            </p>
          ))}
          </div>
      </div>

      <div className="mt-6">
        <button
          className="border border-primary rounded-md md:w-1/2 max-md:w-full h-11 items-center 
                justify-center hover:bg-primary transition duration-300 ease-in-out"
          onClick={openOfflineService}
        >
          {t('OfflineService')}
        </button>
      </div> 
    </>
  );
};

export default SupportTicketRightPart;
