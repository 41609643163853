import React, { ReactNode } from "react";
import TopMenuCabinet from "../topMenuCabinet/topMenuCabinet";
import AdminLeftMenu from "./adminLeftMenu/adminLeftMenu";

interface AdminLayoutProps {
  children: ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  return (
    <div className="relative flex min-h-screen">
      <div className="max-md:fixed max-md:inset-y-0 max-md:left-0 max-md:z-50">
        <AdminLeftMenu />
      </div>
      <div className="flex-1 flex flex-col ml-0">
        <TopMenuCabinet />
        <div className="p-6 flex-grow">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
