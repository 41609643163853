import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { useUploadConfigStore } from "../../../stores/admin/uploadConfigStore";
import UploadConfigService from "../../../api/admin/uploadConfigService";

interface UploadConfigProps {
  type: string,
  fileName: string,
  label: string,
  path: string,
}

const AdminUploadConfigMain: React.FC<UploadConfigProps> = ({type, fileName, label, path}) => {
  const { t } = useTranslation();

  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const { selectedFile, setSelectedFile } = useUploadConfigStore();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({
      label,
      path,
    });
    setSelectedFile(null);
  }, []);

  const [highlighted, setHighlighted] = useState(false);
  const [inWork, setInWork] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileInfo, setUploadedFileInfo] = useState("");

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const uploadFile = async (file: File) => {
    if (inWork)
      return;

    if (file) {
      const uploader = new UploadConfigService();
      try {
        var uploadedFileInfo = await uploader.uploadFile(file, type, {
          onProgress: (progress) => {
            setUploadProgress(progress);
          },
        });
        setInWork(true);
        setSelectedFile(null);
        setUploadedFileInfo(uploadedFileInfo);
        setUploadProgress(0);
      } catch (error) {
        // Handle error
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case when no file is selected
      console.error("No file selected for upload.");
    }
  };

  const downloadFile = () => {
    setInWork(true);
    try{
      const uploadConfigService = new UploadConfigService();
      uploadConfigService.downloadFile(type, fileName);
    }
    finally{
      setInWork(false);
    }
  }

  return (
    <>
      <div
        className={`flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72             
                ${highlighted ? "border-primary bg-content2" : ""}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {selectedFile && (
          <div className="mt-4 relative">
            <div className="relative z-10">
              <h1 className="text-2xl pb-4"> {t("Upload progress")}</h1>
              <p className="hidden md:block text-textsecondary text-sm pb-4">
                {t("Wait until the file is uploaded")}
              </p>

              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-tablebg2">
                <div
                  style={{ width: `${uploadProgress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-primary transition-width duration-500 ease-in-out"
                ></div>
              </div>
            </div>
          </div>
        )}

        {!selectedFile && (
          <div className="text-center">
            <h1 className="text-2xl pb-4">{t("Upload a new file")}</h1>
            <p className="hidden md:block text-textsecondary pb-4">
              {t("Drag the file to the highlighted area and click upload file")}
            </p>
            <div className="flex justify-center">
              <div className="py-5">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  id="fileInput"
                />
                <label
                  htmlFor="fileInput"
                  className="text-center cursor-pointer py-5 px-14 rounded-lg bg-primary"
                >
                  {t("Upload")}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="py-10">
        <label
          className="text-center cursor-pointer py-5 px-14 rounded-lg border border-primary hover:bg-primary transition duration-300 ease-in-out"
          onClick={downloadFile}
        >
          {t("Download current config")}
        </label>
      </div>

      {uploadedFileInfo && (
        <div className="flex-1 flex flex-col justify-center items-center border border-primary rounded-xl border-dashed h-72">
          <p className="text-2xl">{uploadedFileInfo}</p>
        </div>
      )}
    </>
  );
};

export default AdminUploadConfigMain;
