import React, { useEffect, useRef } from "react";
import { Attachment } from "react-huge-icons/outline";
import { useTranslation } from "react-i18next";
import useAdminSupportStore from "../../../stores/admin/adminSupportStore";
import AdminSupportService, {
  MessageResult,
} from "../../../api/admin/adminSupportService";
import { useModalBusyLoadingStore } from "../../../stores/modalBusyLoadingStore";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../../core/constantRoutes";

interface AdminSupportChatProps {
  ticket: number;
}

const AdminSupportChat: React.FC<AdminSupportChatProps> = ({ticket}) => {
  const { t } = useTranslation();  
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setModalOpen } = useModalBusyLoadingStore();

  const { messages, fetchMessages, message, setMessage } = useAdminSupportStore();
  const {addBreadcrumb, clearBreadcrumbs} = useBreadcrumbsStore();          

  const supportService = new AdminSupportService();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({label: 'Support Center', path: ConstantRoutes.adminSupportCenter})
    addBreadcrumb({label: ticket.toString(), path: ConstantRoutes.adminSupportCenter + '/' + ticket})

    setMessage("");
    fetchMessages(Number(ticket));
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage.length > 0) {
      setMessage("");
      await supportService.sendMessage(Number(ticket), trimmedMessage);

      fetchMessages(Number(ticket));
    }
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setModalOpen(true);      
      await supportService.uploadFile(Number(ticket), file);
      fetchMessages(Number(ticket));
      setModalOpen(false);      
    }
  };

  const downloadMessageFile = async (id: number) => {
    supportService.downloadMessageFile(Number(ticket), id);
  };

  return (
    <>
      <div className="bg-content2 md:w-2/3 max-md:w-full rounded-xl p-5 h-screen">
        <div
          className="bg-content3 rounded-xl p-5 overflow-y-auto h-4/6"
          ref={messagesEndRef}
        >
          {messages.map((message: MessageResult, index: number) => (
            <>
              {message.userId === "True" && message.chatMessageType === 0 && (
                <div className="flex flex-col items-end mb-4">
                  <p className="text-sm text-textsecondary pb-2">
                    {new Date(message.createdOn).toLocaleString("ru-RU")}
                  </p>
                  <div className="bg-content1 rounded-lg p-4 w-2/3 overflow-hidden whitespace-normal break-all">
                    {message.text}
                  </div>
                </div>
              )}

              {message.userId === "False" && message.chatMessageType === 0 && (
                <div className="flex flex-col items-start mb-4">
                  <p className="text-sm text-textsecondary pb-2 ">
                    {new Date(message.createdOn).toLocaleString("ru-RU")}
                  </p>
                  <div className="bg-content2 rounded-lg p-4 w-2/3 overflow-hidden whitespace-normal break-all">
                    {message.text}
                  </div>
                </div>
              )}

              {message.userId === "True" && message.chatMessageType === 1 && (
                <div className="flex flex-col items-end mb-4">
                  <p className="text-sm text-textsecondary pb-2">
                    {new Date(message.createdOn).toLocaleString("ru-RU")}
                  </p>
                  <div className="bg-content1 rounded-lg p-4 w-2/3 overflow-hidden whitespace-normal break-all">
                    [{message.text.split(";")[1] || ""}]
                    <button
                      className={`text-center cursor-pointer rounded-lg h-11 w-32 
                    bg-primary hover:animate-pulse transition duration-300 ease-in-out`}
                      onClick={() => downloadMessageFile(message.id)}
                    >
                      {t("Download")}
                    </button>
                  </div>
                </div>
              )}

              {message.userId === "False" && message.chatMessageType === 1 && (
                <div className="flex flex-col items-start mb-4">
                  <p className="text-sm text-textsecondary pb-2">
                    {new Date(message.createdOn).toLocaleString("ru-RU")}
                  </p>
                  <div className="bg-content2 rounded-lg p-4 w-2/3 overflow-hidden whitespace-normal break-all">
                    {message.text.split(";")[1] || ""}

                    <button
                      className="border border-primary rounded-md w-44 h-11 items-center 
                          justify-center hover:bg-primary transition duration-300 ease-in-out"
                      onClick={() => downloadMessageFile(message.id)}
                    >
                      {t("Download")}
                    </button>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>

        <div className="flex items-center bg-content3 rounded-xl p-2 mt-3 h-14">
          <input
            type="text"
            className="flex-grow outline-none px-2 py-1 bg-content3 w-8"
            placeholder={t("Your message")}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleSendMessage();
              }
            }}
          />
          <button
            className="bg-primary rounded-md w-28 h-roundedcontact mr-3"
            onClick={() => handleSendMessage()}
          >
            {t("Send")}
          </button>
          <button
            className="border border-divider rounded-md w-roundedcontact h-roundedcontact flex items-center
           justify-center hover:bg-primary transition duration-300 ease-in-out"
            onClick={handleFileSelect}
          >
            <Attachment className="w-6 h-6" />
          </button>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileUpload}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSupportChat;
