import { t } from "i18next";
import React from "react";
import { Coin } from "react-huge-icons/outline";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { useAuthStore } from "../../stores/authStore";
import { FirmwareProcessInfo } from "../../models/firmware";
import UploadFirmwareService from "../../api/uploadFirmwareService";
import OrdersService from "../../api/orderHistoryService";
import { useModalBusyLoadingStore } from "../../stores/modalBusyLoadingStore";
import { useModalRefillBalanceStore } from "../../stores/modalRefillBalanceStore";

const DashboardSubmit: React.FC = () => {
  const {
    maxPatchPrice,
    plusPatchPrice,
    checkedOnlinePatches,
    checkedOfflinePatches,
    checkedTgFlashPatches,
    stockFile,
    fixFile,
    firmwareInfo,
    comment,
  } = useUploadStore();
  const { firmwareProcessResult, setFirmwareProcessResult } = useUploadStore();
  const { balance, setBalance } = useAuthStore();
  const ordersService = new OrdersService();
  const { setModalOpen } = useModalBusyLoadingStore();
  const { setModalAttentionOpen } = useModalRefillBalanceStore();

  const processFirmware = async () => {
    setBalance((balance ?? 0) - getPrice());

    const firmwareProcessInfo: FirmwareProcessInfo = {
      stockChecked: stockFile === true,
      fixChecked: fixFile === true,
      patches: [
        ...checkedOnlinePatches,
        ...checkedOfflinePatches,
        ...checkedTgFlashPatches,
      ],
      comment: comment ?? "",
    };

    setModalOpen(true);
    const uploader = new UploadFirmwareService();
    const processResult = await uploader.ProcessFile(firmwareProcessInfo);
    setFirmwareProcessResult(processResult);

    setTimeout(() => {
      setModalOpen(false);
    }, 5000);
  };

  const attentionProcessFirmware = async () => {
    setModalAttentionOpen(true, processFirmware);
  }

  const getPrice = () =>
    (maxPatchPrice ?? 0) +
    (plusPatchPrice ?? 0) +
    (stockFile === true ? firmwareInfo?.stockFilePrice ?? 0 : 0) +
    (fixFile === true ? firmwareInfo?.fixFilePrice ?? 0 : 0);

  const downloadFile = async () => {
    ordersService.downloadFile(firmwareProcessResult?.fileId!);
  };

  return (
    <>
      {!firmwareProcessResult && (
        <>
          <p className="px-8 text-xl max-md:hidden">{t("Price")}</p>
          {(checkedOnlinePatches.length > 0 ||
            checkedOfflinePatches.length > 0 ||
            checkedTgFlashPatches.length > 0 ||
            stockFile === true ||
            fixFile === true) && (
            <div className="md:px-2 xl:px-8">
              <div className="my-4 p-5 border rounded-xl border-tableline max-md:flex max-md:flex-row">
                <div className="flex md:flex-row max-md:flex-col justify-between">
                  <p className="text-textsecondary text-lg px-4">
                    {t("Total")}
                  </p>
                  <div className="flex flex-row px-4">
                    <p className="text-4xl text-right">{getPrice()}</p>
                    <Coin className="ml-2 w-10 h-10" />
                  </div>
                </div>

                {getPrice() <= (balance ?? 0) && (
                  <>
                    <button
                      className="md:hidden text-center cursor-pointer rounded-lg 
                      bg-primary h-11 w-full hover:animate-pulse transition duration-300 ease-in-out mt-4"
                      onClick={attentionProcessFirmware}
                    >
                      {t("Submit")}
                    </button>
                    <button
                      className="max-md:hidden text-center cursor-pointer rounded-lg 
                      bg-primary h-11 w-full hover:animate-pulse transition duration-300 ease-in-out mt-4"
                      onClick={processFirmware}
                    >
                      {t("Submit")}
                    </button>
                    <h1 className="text-textsecondary mt-5 max-md:hidden">
                      <span className="text-danger">{t("Attention")}</span>{" "}
                      {t("Attention firmware upload text")}
                    </h1>
                  </>
                )}

                {getPrice() > (balance ?? 0) && (
                  <>
                    <h1 className="text-danger text-2xl">
                      {t("Not enough coins")}
                    </h1>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {firmwareProcessResult?.patchState === 4 && (
        <>
          <div className="px-8">
            <div className="my-4 p-5 border rounded-xl border-tableline">
              <button className="text-center rounded-lg bg-textsecondary h-11 w-full pointer-events-none ease-in-out mt-4">
                {t("In work")}
              </button>
            </div>
          </div>
        </>
      )}

      {firmwareProcessResult?.patchState === 3 && (
        <>
          <div className="px-8">
            <div className="my-4 p-5 border rounded-xl border-tableline">
              <button
                className="text-center cursor-pointer rounded-lg bg-success h-11 w-full hover:animate-pulse transition duration-300 ease-in-out mt-4"
                onClick={downloadFile}
              >
                {t("Download")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardSubmit;
