import { Outlet } from "react-router-dom";
import { useAuthStore } from "../../stores/authStore";
import CabinetLayout from "./cabinetLayout";
import EmailNotConfirmed from "./emailNotConfirmed";

function Cabinet() {
  const {isEmailConfirmed} = useAuthStore();

  return (
    <>
      <CabinetLayout>
        {isEmailConfirmed &&  <Outlet />}
        {!isEmailConfirmed && <EmailNotConfirmed />} 
      </CabinetLayout>
    </>
  );
}

export default Cabinet;
