import React, { ReactNode } from "react";
import LeftMenu from "../leftMenu/leftMenu";
import TopMenuCabinet from "../topMenuCabinet/topMenuCabinet";

interface CabinetLayoutProps {
  children: ReactNode;
}

const CabinetLayout: React.FC<CabinetLayoutProps> = ({ children }) => {
  return (
    <div className="relative flex min-h-screen">
      <div className="max-md:fixed max-md:inset-y-0 max-md:left-0 max-md:z-50">
        <LeftMenu />
      </div>
      <div className="flex-1 flex flex-col ml-0">
        <TopMenuCabinet />
        <div className="lg:p-2 xl:p-6 flex-grow">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CabinetLayout;
