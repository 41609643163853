import Dropdown from "../../dropdown/dropdown";
import { useRemappingStore } from "../../../stores/remappingStore";
import { useEffect, useState } from "react";
import RemappingDropdown from "./remappingDropdown";
import { useTranslation } from "react-i18next";

const RemappingDataBase: React.FC = () => {
  const {
    series,
    fetchSeries,
    generations,
    fetchGenerations,
    engines,
    fetchEngines,
    ecus,
    fetchEcus,
    changes,
    fetchChanges,
    clearSeries,
    clearChanges,
    clearEcus,
    clearEngines,
    clearGenerations,
  } = useRemappingStore();

  const [selectedSeries, setSelectedSeries] = useState("");
  const [selectedGenerations, setSelectedGenerations] = useState("");
  const [selectedEngines, setSelectedEngines] = useState("");
  const [selectedEcus, setSelectedEcus] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    fetchSeries();
  }, []);

  return (
    <>
      <div className="flex flex-col z-10">
        <div className="max-w-screen-2xl w-full place-self-center text-center pb-10">
          <h1 className="text-4xl font-bold">{t('Remapping Data Base')}</h1>
        </div>

        <div className="max-w-screen-2xl w-full place-self-center max-md:p-4">
          <div className="rounded-xl bg-content3 grid md:grid-flow-col max-md:grid-flow-row justify-stretch">
            <div className="md:py-10 md:pl-10 md:pr-5 max-md:p-5 min-w-full">
              <p className="text-sm pb-3">{t('Series')}</p>

              <div className="flex align-middle rounded-lg bg-content2 h-12 px-3">
                <RemappingDropdown
                  items={series}
                  onSelect={function (item): void {
                    setSelectedGenerations("");
                    setSelectedEngines("");
                    setSelectedEcus("");
                    clearEngines();
                    clearEcus();
                    clearChanges();

                    setSelectedSeries(item);
                    if (item !== "") fetchGenerations(item);
                  }}
                  caption={selectedSeries}
                />
              </div>
            </div>

            <div className="md:py-10 md:px-5 max-md:p-5 min-w-full">
              <p className="text-sm pb-3">{t('Generation')}</p>

              <div className="flex align-middle rounded-lg bg-content2 h-12 px-3">
                <RemappingDropdown
                  items={generations}
                  onSelect={function (item): void {
                    setSelectedEngines("");
                    setSelectedEcus("");
                    clearEcus();
                    clearChanges();

                    setSelectedGenerations(item);
                    if (item !== "") fetchEngines(selectedSeries, item);
                  }}
                  caption={selectedGenerations}
                />
              </div>
            </div>

            <div className="md:py-10 md:px-5 max-md:p-5 min-w-full">
              <p className="text-sm pb-3">{t('Engine')}</p>

              <div className="flex align-middle rounded-lg bg-content2 h-12 px-3">
                <RemappingDropdown
                  items={engines}
                  onSelect={function (item): void {
                    setSelectedEcus("");
                    clearChanges();

                    setSelectedEngines(item);
                    if (item !== "")
                      fetchEcus(selectedSeries, selectedGenerations, item);
                  }}
                  caption={selectedEngines}
                />
              </div>
            </div>

            <div className="md:py-10 md:pl-5 md:pr-10 max-md:p-5 min-w-full">
              <p className="text-sm pb-3">{t('ECU')}</p>

              <div className="flex align-middle rounded-lg bg-content2 h-12 px-3">
                <RemappingDropdown
                  items={ecus}
                  onSelect={function (item): void {
                    setSelectedEcus(item);
                    if (item !== "")
                      fetchChanges(
                        selectedSeries,
                        selectedGenerations,
                        selectedEngines,
                        item
                      );
                  }}
                  caption={selectedEcus}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-2" />

        {changes.length > 0 && (
          <>
            {changes.map((change) => (
              <>
                <div className="max-w-screen-2xl w-full place-self-center max-md:p-4">
                  <div className="rounded-xl bg-content3 grid grid-flow-col justify-stretch overflow-hidden ">
                    <table className="w-full">
                      <thead className="bg-tablebg2">
                        <tr>
                          <th className="w-1/6 text-left md:p-5 max-md:p-3 text-primary font-normal  border border-tableline">
                            {change.name}
                          </th>
                          <th className="w-1/4 text-left md:p-5 max-md:p-3 text-textsecondary font-normal  border border-tableline">
                            {t('Original')}
                          </th>
                          <th className="w-1/4 text-left md:p-5 max-md:p-3 text-textsecondary font-normal border border-tableline">
                            {t('After tuning')}
                          </th>
                          <th className="w-1/4 text-left md:p-5 max-md:p-3 text-textsecondary font-normal border border-tableline">
                            {t('Difference')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="text-left md:p-5 max-md:p-3 text-textsecondary font-normal border border-tableline bg-tablebg2">
                            {t('Power')}
                          </th>

                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            {change.power.original} hp
                          </td>
                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            {change.power.afterTuning} hp
                          </td>
                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            + {change.power.difference} hp
                          </td>
                        </tr>

                        <tr>
                          <th className="text-left md:p-5 max-md:p-3 text-textsecondary font-normal border border-tableline bg-tablebg2">
                            {t('Torque')}
                          </th>

                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            {change.torque.original} nm
                          </td>
                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            {change.torque.afterTuning} nm
                          </td>
                          <td className="md:p-5 max-md:p-3 border border-tableline">
                            + {change.torque.difference} nm
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default RemappingDataBase;
