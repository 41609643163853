import { create } from "zustand";
import WorktimeService from "../api/worktimeService";

export interface WorktimeDateResult {
  open: Date;
  close: Date;
}

interface WorktimeStore {
  worktimes: WorktimeDateResult | null;
  fetchWorktimes: () => Promise<void>;
  setWorktimes: (worktimes: WorktimeDateResult) => void;
}

const convertUTCToLocal = (utcDateStr: string): Date => {
  const utcDate = new Date(utcDateStr); 
  return utcDate; 
}
export const useWorktimeStore = create<WorktimeStore>((set) => ({
  worktimes: null,
  fetchWorktimes: async () => {
    const worktimeService = new WorktimeService();
    try {
      const worktimeString = await worktimeService.getWorktimes();
      console.log(worktimeString);
      const worktimes = {
        open: convertUTCToLocal(worktimeString.open),
        close: convertUTCToLocal(worktimeString.close)
      };
      console.log(worktimes);
      set({ worktimes });
    } catch (error) {
      console.error("Failed to fetch worktimes:", error);
    }
  },
  setWorktimes: (worktimes) => set({ worktimes })
}));