import { create } from "zustand";

export interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbsState {
  breadcrumbs: BreadcrumbItem[];
  addBreadcrumb: (breadcrumb: BreadcrumbItem) => void;
  clearBreadcrumbs: () => void;
}

const useBreadcrumbsStore = create<BreadcrumbsState>((set) => ({
  breadcrumbs: [],
  addBreadcrumb: (breadcrumb) =>
    set((state) => ({
      breadcrumbs: [...state.breadcrumbs, breadcrumb],
    })),
  clearBreadcrumbs: () =>
    set(() => ({
      breadcrumbs: [],
    })),
}));

export default useBreadcrumbsStore;
