import { create } from "zustand";
import OfflineSettingsService, { OfflineSettingsRootResult } from "../api/offlinesettingsService";

interface OfflineSettingsStore {
  offlineSettings: OfflineSettingsRootResult[];
  fetchOfflineSettings: () => Promise<void>;
}

export const useOfflineSettingsStore = create<OfflineSettingsStore>((set) => ({
  offlineSettings: [],
  fetchOfflineSettings: async () => {
    const offlineSettingsService = new OfflineSettingsService();
    try {
      const offlineSettings = await offlineSettingsService.getOfflineSettings();
      set({ offlineSettings });
    } catch (error) {}
  }   
}));
