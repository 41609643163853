import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConstantRoutes } from '../../../core/constantRoutes';

interface AdminSupportItemProps {
    title: string;
    date: string;
    showDot: boolean;
    ticket: number;
  }

const AdminSupportItem: React.FC<AdminSupportItemProps> = ({ title, date, showDot, ticket }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpenTicket = () => {
    navigate(`${ConstantRoutes.adminSupportCenter}/${ticket}`);
  };  

  return (
    <div className="flex justify-center pb-6">
      <div className="w-full relative">
        <div className="rounded-2xl h-24 bg-content2 flex items-center justify-between px-6">
          <div>
            <div className="flex items-center">
              {showDot && <div className="w-2 h-2 bg-primary rounded-full mr-2"></div>}
              <p className='font-medium text-sm text-textsecondary p-3'>№{ticket}</p>
              <p className="font-medium text-lg">{title}</p>
            </div>
            <p className="text-sm text-textsecondary">{date}</p>
          </div>
          <button
            className="flex items-center justify-center border-primary border border-solid rounded-lg px-3 
            cursor-pointer hover:bg-primary h-10 w-28 transition duration-300 ease-in-out"
            onClick={handleOpenTicket}
          >
            {t('OpenSupport')}
          </button>
        </div>
      </div>        
    </div>
  );
};

export default AdminSupportItem;