import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useEmailStore } from "../../../stores/admin/emailStore";
import EmailSettingsService from "../../../api/admin/emailSettingsService";
import { useWorkTimeStore } from "../../../stores/admin/workTimeStore";
import WorkTimeService from "../../../api/admin/workTimeService";
import WorktimeService from "../../../api/worktimeService";

const AdminEmail: React.FC = () => {
  const { t } = useTranslation();
  const {
    workTime,
    fetchWorkTime,
    updateHours,
    updateMinutes
  } = useWorkTimeStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({ label: "Work Time", path: ConstantRoutes.adminWorkTime });

    fetchWorkTime();
  }, []);

  const saveWorkTime = async () => {
    const workTimeService = new WorkTimeService();
    if (workTime !== undefined) {
      await workTimeService.saveWorkTimes(workTime);
      fetchWorkTime();
    }
  };

  const iAmHere = async () => {
    const workTimeService = new WorkTimeService();
    if (workTime !== undefined) {
      await workTimeService.saveWorkTimes({hours: 0, minutes: 0});
      fetchWorkTime();
    }
  }

  return (
    <>
      <div className="flex-1 flex flex-col p-2">

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("Hours")}</label>
          <input
            type="number"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Hours")}
            value={workTime?.hours || 0}
            onChange={(event) => updateHours(Number(event.target.value))}
          />
        </div>

        <div className="w-1/2 mb-4">
          <label className="text-textsecondary">{t("Minutes")}</label>
          <input
            type="number"
            className="flex-grow outline-none px-2 py-1 bg-content2 w-full border border-secondary rounded-xl"
            placeholder={t("Minutes")}
            value={workTime?.minutes || 0}
            onChange={(event) =>  updateMinutes(Number(event.target.value))}
          />
        </div>

        <button
          className="text-center cursor-pointer rounded-lg bg-primary h-11 w-1/2 hover:animate-pulse transition duration-300 ease-in-out my-4"
          onClick={saveWorkTime}
        >
          {t("Save")}
        </button>

        <button
          className="text-center cursor-pointer rounded-lg bg-success h-11 w-1/2 hover:animate-pulse transition duration-300 ease-in-out my-4"
          onClick={iAmHere}
        >
          {t("I am here")}
        </button>
      </div>
    </>
  );
};

export default AdminEmail;
