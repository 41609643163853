import { create } from "zustand";
import RemappingService, { ChangesResult } from "../api/remappingService";

interface RemappingStore {
  series: string[];
  fetchSeries: () => Promise<void>;
  clearSeries: () => void;
  generations: string[];
  fetchGenerations: (series: string) => Promise<void>;
  clearGenerations: () => void;  
  engines: string[];
  fetchEngines: (series: string, generation: string) => Promise<void>;
  clearEngines: () => void;
  ecus: string[];
  fetchEcus: (series: string, generation: string, engine: string) => Promise<void>;
  clearEcus: () => void;
  changes: ChangesResult[];
  fetchChanges: (series: string, generation: string, engine: string, ecu: string) => Promise<void>;
  clearChanges: () => void;
}

export const useRemappingStore = create<RemappingStore>((set) => ({
  series: [],
  fetchSeries: async () => {
    const remappingService = new RemappingService();
    try {
      const series = await remappingService.getSeries();
      set({ series });
    } catch (error) {}
  },
  clearSeries: () => {
    const series: string[] = [];
    set({series})
  },

  generations: [],
  fetchGenerations: async (series: string) => {
    const remappingService = new RemappingService();
    try {
      const generations = await remappingService.getGenerations(series);
      set({ generations });
    } catch (error) {}
  },
  clearGenerations: () => {
    const generations: string[] = [];
    set({generations})
  },  

  engines: [],
  fetchEngines: async (series: string, generation: string) => {
    const remappingService = new RemappingService();
    try {
      const engines = await remappingService.getEngines(series, generation);
      set({ engines });
    } catch (error) {}
  },
  clearEngines: () => {
    const engines: string[] = [];
    set({engines})
  },    

  ecus: [],
  fetchEcus: async (series: string, generation: string, engine: string) => {
    const remappingService = new RemappingService();
    try {
      const ecus = await remappingService.getEcus(series, generation, engine);
      set({ ecus });
    } catch (error) {}
  },
  clearEcus: () => {
    const ecus: string[] = [];
    set({ecus})
  },   

  changes: [],
  fetchChanges: async (series: string, generation: string, engine: string, ecu: string) => {
    const remappingService = new RemappingService();
    try {
      const changes = await remappingService.getChanges(series, generation, engine, ecu);
      set({ changes });
    } catch (error) {}
  },
  clearChanges: () => {
    const changes: ChangesResult[] = [];
    set({changes})
  },    
}));
