import axios, { AxiosResponse } from "axios";
import getConfig from "../../config/configUtil";

const conf = getConfig;

export interface EmailSettingsResult {
  from: string;
  password: string;
  fromName: string;
  smtpPort: number;
  smtpAddress: string;
  confirmSubject: string;
  confirmMessage: string;
  isSecure: boolean;
  forgotPasswordMessage: string;
  forgotPasswordSubject: string;
  resetPasswordMessage: string;
  resetPasswordSubject: string;
}

class EmailSettingsService {
  async getEmailSettings(): Promise<EmailSettingsResult> {
    try {
      const response: AxiosResponse = await axios.get(conf.BACKEND + "admin/email/get");
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch email settings");
    }
  }

  async saveEmailSettings(emailSettings: EmailSettingsResult) : Promise<void> {
    try{
      await axios.post(conf.BACKEND + "admin/email/save", emailSettings);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to save email settings");
    }
  }  

  async sendTestToken(email: string) : Promise<string> {
    try{
      const response: AxiosResponse = await axios.post(conf.BACKEND + "admin/email/test/" + email);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to send test token");
    }
  }  
}

export default EmailSettingsService;
