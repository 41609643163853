import React from "react";
import AdminTableServiceMain from "../adminTableServiceMain/adminTableServiceMain";
import AdminTableServiceRecord from "../adminTableServiceMain/adminTableServiceRecord";

const AdminOfflineServiceRecord: React.FC = () => {
  return(
      <>
          <AdminTableServiceRecord label="Admin offline service" path="offlineservice" />
      </>
  )
}

export default AdminOfflineServiceRecord;
