import { Routes, Route } from "react-router-dom";
import UploadFirmware from "./components/uploadFirmwarePage/uploadFirmware";
import DamosMappack from "./components/damosMappackPage/damosMappack";
import OrderHistory from "./components/orderHistoryPage/orderHistory";
import OriginalFile from "./components/originalFilePage/originalFile";
import Support from "./components/supportPage/support";
import Modal from "./components/modal/modalBase";
import SupportTicket from "./components/supportPage/supportTicket";
import PrivateWrapper from "./core/privateWrapper";
import { useAuthStore } from "./stores/authStore";
import BusyLoading from "./components/modal/busyLoading";
import MainPage from "./components/main/mainPage";
import Cabinet from "./components/cabinet/cabinet";
import AuthorizationModal from "./components/authorizationPage/authorizationModal";
import ModalForgotPassword from "./components/modal/modalForgotPassword";
import ModalSuccess from "./components/modal/modalSucces";
import ModalAttention from "./components/modal/modalAttention";
import Admin from "./components/admin/admin";
import AdminUsers from "./components/admin/adminUsers/adminUsers";
import ModalConfirmAction from "./components/modal/admin/modalConfirmAction";
import AdminEmail from "./components/admin/aminEmail/adminEmail";
import AdminWorkTime from "./components/admin/adminWorkTime/adminWorkTime";
import AdminAllowedSizes from "./components/admin/adminAllowedSizes/adminAllowedSizes";
import AdminCompares from "./components/admin/adminCompares/adminCompares";
import AdminSignatures from "./components/admin/adminSignatures/adminSignatures";
import AdminSwid from "./components/admin/adminSwid/adminSwid";
import AdminGroups from "./components/admin/adminGroups/adminGroups";
import AdminCapacityTable from "./components/admin/adminCapacityTable/adminCapacityTable";
import AdminFaq from "./components/admin/adminFaq/adminFaq";
import AdminNews from "./components/admin/adminNews/adminNews";
import AdminOfflineService from "./components/admin/adminOfflineService/adminOfflineService";
import AdminOfflineServiceRecord from "./components/admin/adminOfflineService/adminOfflineServiceRecord";
import AdminSupportCenter from "./components/admin/adminSupportCenter/adminSupportCenter";
import AdminSupportCenterChat from "./components/admin/adminSupportCenter/adminSupportCenterChat";
import AdminOfflineSettings from "./components/admin/adminOfflineSettings/adminOfflineSettings";
import { useEffect } from "react";
import AdminOnlineService from "./components/admin/adminOnlineService/adminOnlineService";
import AdminOnlineServiceRecord from "./components/admin/adminOnlineService/adminOnlineServiceRecord";

function App() {
  const { isAuthenticated, roles } = useAuthStore();

  useEffect(() => {
    // Check if there's a hash in the URL
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });

          if (element instanceof HTMLElement && typeof element.focus === 'function') {
            element.focus();
          }
        }
      }, 1000); // Delay the scroll to allow other updates to complete
    }
  }, []); // Empty dependency array to run only on mount
  
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/confirm/" element={<MainPage />} />
        <Route path="/reset-password/" element={<MainPage />} />
        <Route
          element={
            <PrivateWrapper auth={{ isAuthenticated: isAuthenticated() }} />
          }
        >
          <Route path="/cabinet/*" element={<Cabinet />}>
            <Route index path="file-processing" element={<UploadFirmware />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route path="original-file" element={<OriginalFile />} />
            <Route path="damos-mappack" element={<DamosMappack />} />
            <Route path="support" element={<Support />} />
            <Route path="support/ticket/:ticket" element={<SupportTicket />} />
          </Route>
        </Route>

        <Route
          element={
            <PrivateWrapper auth={{isAuthenticated: isAuthenticated()}} />
          }
          >
          <Route path="/admin-tech/*" element={<Admin/>} >
            <Route index path="users" element={<AdminUsers/>} />
            <Route index path="offlineservice" element={<AdminOfflineService/>} />
            <Route index path="offlineservice/work/:file" element={<AdminOfflineServiceRecord/>} />
            <Route index path="onlineservice" element={<AdminOnlineService/>} />
            <Route index path="onlineservice/work/:file" element={<AdminOnlineServiceRecord/>} />
            <Route index path="emailsettings" element={<AdminEmail/>} />
            <Route index path="supportcenter" element={<AdminSupportCenter/>} />
            <Route index path="supportcenter/:ticket" element={<AdminSupportCenterChat/>} />
            <Route index path="worktime" element={<AdminWorkTime/>} />
            <Route index path="allowedsizes" element={<AdminAllowedSizes />} />
            <Route index path="compares" element={<AdminCompares/>} />
            <Route index path="signatures" element={<AdminSignatures/>} />
            <Route index path="swid" element={<AdminSwid/>} />
            <Route index path="groups" element={<AdminGroups/>} />
            <Route index path="capacitytable" element={<AdminCapacityTable/>} />
            <Route index path="faq" element={<AdminFaq/>} />
            <Route index path="news" element={<AdminNews/>} />
            <Route index path="offlinesettings" element={<AdminOfflineSettings />} />
          </Route>
        </Route>
      </Routes>

      <Modal />

      <BusyLoading />

      <AuthorizationModal />

      <ModalForgotPassword />

      <ModalSuccess />

      <ModalAttention />

      <ModalConfirmAction />
    </>
  );
}

export default App;
