import {create} from 'zustand';
import SupportService, { ChatResult, MessageResult } from '../../api/supportService';
import { OrderHistoryRecordResult } from '../../api/orderHistoryService';
import AdminSupportService from '../../api/admin/adminSupportService';

interface AdminChatState {
  chats: ChatResult[];
  fetchChats: () => Promise<void>;
  messages: MessageResult[];
  fetchMessages: (chatId: number) => Promise<void>;
  fileInfo: OrderHistoryRecordResult | null;
  fetchFileInfo: (chatId: number) => Promise<void>;
  message: string;
  setMessage: (message: string) => Promise<void>;
}

const useAdminSupportStore = create<AdminChatState>((set) => ({
  chats: [],
  fetchChats: async () => {
    const supportService = new AdminSupportService();
    try {
      const chats = await supportService.getChats();
      set({ chats });
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  },
  messages: [],
  fetchMessages: async (chatId: number) => {
    const supportService = new AdminSupportService();
    try{
      const messages = await supportService.getMessages(chatId);
      set({messages});    
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  },
  fileInfo: null,
  fetchFileInfo: async (chatId: number) => {
    const supportService = new AdminSupportService();
    try{ 
      const fileInfo = await supportService.orderInfo(chatId);
      set({fileInfo});
    } catch (error) {
      console.error('Error fetching chat order info:', error);
    }
  },
  message: "",
  setMessage: async (message: string) => {set({message})}
}));

export default useAdminSupportStore;