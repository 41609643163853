import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

interface AuthState {
  isAuthenticated: boolean;
}

interface PrivateWrapperProps {
  auth: AuthState;
}

const PrivateWrapper: React.FC<PrivateWrapperProps> = ({ auth }) => {
  return auth.isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateWrapper;