import {create} from 'zustand';
import OrdersService from '../api/orderHistoryService';

interface OrderState {
  orders: any[];
  fetchOrders: () => Promise<void>;
}

const useOrderStore = create<OrderState>((set) => ({
  orders: [],
  fetchOrders: async () => {
    const ordersService = new OrdersService();
    try {
      const orders = await ordersService.orders();
      set({ orders });
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  },
}));

export default useOrderStore;