import { create } from 'zustand';

interface UploadConfigStore {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}

export const useUploadConfigStore = create<UploadConfigStore>((set) => ({
  selectedFile: null,
  setSelectedFile: (file) => set({ selectedFile: file }),  
}));