import axios, { AxiosResponse, AxiosRequestConfig, AxiosProgressEvent} from 'axios';
import getConfig from '../config/configUtil';
import { FirmwareInfo, FirmwareProcessInfo, FirmwareProcessResult } from '../models/firmware';

const conf = getConfig;

interface UploadOptions {
    onProgress?: (progress: number) => void;
}

export default class UploadFirmwareService {
    async uploadFile(file: File, options?: UploadOptions): Promise<FirmwareInfo> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent : AxiosProgressEvent) => {
                if (options?.onProgress) {
                    const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 1)) * 90);
                    options.onProgress(progress);
                }
            },
        };

        try {
            const response: AxiosResponse<FirmwareInfo> = await axios.post(conf.BACKEND + 'uploadFirmware/upload', formData, config);
            console.log('File uploaded successfully:', response.data);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios Error:', error.message);
            } else {
                console.error('Error uploading file:', error);
            }
            throw error;
        }
    }

    async ProcessFile(firmwareProcessInfo: FirmwareProcessInfo) : Promise<FirmwareProcessResult> {
        try {
            const reponse: AxiosResponse<FirmwareProcessResult> = await axios.post(conf.BACKEND + 'uploadFirmware/process', firmwareProcessInfo);

            return reponse.data;
        } catch (error){
            console.error('Error:', error);

            throw error;
        }
    }
}